.sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 40px;
  max-width: 500px;
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  z-index: 10000;
  transition: 300ms;
} 
.sidebar.opened {
  min-width: 223px; 
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 80vw;
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 1000;
  transition: 0ms;
}
.resizable{
  height: 100vh;
  margin-top: -64px;
}
.sidebar_fix{
  position: fixed;
  background: #fcfafb;
  width: 40px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.sidebar_fix.opened {
  width: 225px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.app-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
  /* border:5px solid orange; */


}

.sidebar .app-sidebar-content {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: 90vh;
  margin-top: 50px;
  /* border:5px solid purple; */
}

.sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  /* resize: horizontal; */
  /* position: fixed; */
  /* border:5px solid green; */


}

.sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
}

.sidebar-content {
  width:100%;
/* border:5px solid black; */

}