.whyichest_base{
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
}
.copyright{
    background-color:#1A212A ;
    color: white;
    text-align: center;
    margin-top: -2px;
}
.copyright_text{
    color: white !important;
}
.copyright a {
	text-decoration: none;
    color: #a1a1ff;
    transition: all 300ms;
}
.copyright a:hover{
	text-decoration: none;
    color: #b486ff;
}

.copyright .list-unstyled,
.copyright .statement {
	opacity: 1;
}
.partner{
 text-decoration: none !important;
 color: white;
 margin-left: 10px;
}
.partner:hover{
    color: white;
    text-decoration: underline !important;
}
.whyichest_main{
    margin-top: 60px;
    margin-bottom: 50px;
    min-width: 300px;
    width: 90%;
    max-width: 1150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: yellow; */
}
.whyichest_heading{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.whyichest_heading h1{
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600;
    color: black;
    margin-right: 10px;
}
.whyichest_heading_logo{
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.whyichest_paragraph p{
    text-indent: 50px;
    color:black;
    text-align: justify;
    font-weight: 400;

}
/* whyichest_second_para */
.whyichest_second_para{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}
.whyichest_image{
    width: 30%;
    min-width: 200px;
    max-width: 420px;
    max-height: 380px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.whyichest_image_img{
    height: 100%;
    width: 100%;
    object-fit: cover !important;

}
.whyichest_content{
    min-width:300px;
    width: 65%;
    text-align: justify;
}
.whyichest_content p{
    text-indent: 50px;
    color: black;
    font-weight: 400;
}
.whyichest_last_para p{
    text-indent: 50px;
    color: black;
    font-weight: 400;
    text-align: justify;
}
.fueatures_page{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 668px) {

    .whyichest_heading h1{
        margin-top: 10px;
        font-size: 33px;
        font-weight: 600;
        color: black;
        margin-right: 5px;
    }
    .whyichest_heading_logo{
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
    .whyichest_second_para{
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
    }
    .whyichest_image{
        width: 70%;
        display: flex;
        justify-content: center;
        min-width: 200px;
        overflow: hidden;
    }
    .whyichest_image_img{
        object-fit: cover;
    
    }
    .whyichest_content{
        min-width:300px;
        width: 100%;
        text-align: justify;
    }
    
}