@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");

body,
:root {
  --box-shadow: rgba(102, 118, 147, 0.25) 0px 1px 1px,
    rgba(62, 74, 95, 0.13) 0px 0px 1px 1px;
  --font-family: "Roboto Condensed", sans-serif;
  --font-family-base: "Roboto Flex", sans-serif;
  --card-color: #eef5ff;
}

.test {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
}

.test1 {
  width: calc(100vw - 5%);
  height: auto;
  box-sizing: border-box;
  position: relative;
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 85vh;
  overflow: hidden;
  padding: 0px 50px 0px 0px;
}

.Assets_Component_list {
  height: 100%;
  width: 18%;
  overflow-y: auto;
  min-width: 200px;
  max-width: 400px;
}

.Assets_Component_list::-webkit-scrollbar {
  display: none !important;
}

.Assets_components {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
}

.Assets_component_base {
  width: 100%;
  display: flex;
  padding: 0px 10px 0px 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.Assets_component_main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Assets_cardtabs {
  height: 80px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: all 300ms;
}

.Assets_cardtabs:hover {
  transform: scale(1.02);
  transition-duration: 0.3s;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.Assets_base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Assets_image {
  height: 70px;
  width: 70px;
  object-fit: contain;
  margin-left: 6px;
}

.Assets_card_details {
  height: 70px;
  display: flex;
  align-items: center;
}

.Assets_details_text {
  color: black;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
  font-family: var(--font-family);
  margin-bottom: 0px !important;
}

/* user main dashboard start  */

.Dashboard_main {
  width: 100%;
  min-width: 250px;
  height: 100%;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard_left {
  width: 73%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.user_details_dash {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.UserDetails {
  width: 39%;
  min-width: 250px;
  background-color: var(--card-color);
  height: 170px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.Username_details {
  font-size: 30px;
  font-weight: 500;
  font-family: var(--font-family-base);
  /* font-family: "Bad Script", cursive; */
  /* font-family: "Zen Maru Gothic", serif; */
  line-height: 35px;
  color: #535353;
  text-align: left;
  padding-bottom: 10px;
}

.date_time_details {
  margin-top: 15px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: "Zen Maru Gothic", serif;
  color: #303867;
  /* line-height: 25px; */
}

.User_action_btns {
  width: 59%;
  height: 180px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

.user_dashbord_btn {
  width: 100%;
  display: flex;
  gap: 3%;
}

.add_owner_button {
  width: 48.5% !important;
}

.dashboard_action_btn {
  text-decoration: none !important;
  height: 50px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  color: #626577;
  font-weight: 500;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--card-color);
  cursor: pointer;
}

.user_chart_dash_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--card-color);
}

.user_charts {
  width: 100%;
}

.user_pie_chart_main {
  display: flex;
}

.user_pie_chart_with_assets {
  display: flex;
  flex-direction: row;
  width: 54%;
}

.user_pie_chart_with_liabilities {
  display: flex;
  flex-direction: row;
  width: 45%;
}

.user_pie_chart_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.pie_chart_details {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.pie_chart_detail_box {
  width: 24px;
  height: 16px;
  background-color: red;
}

.pie_chart_details > p {
  font-size: 14px;
}

.user_chart_dash {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
}

.chart_button {
  display: flex;
  gap: 10px;
  margin-left: 12px;
}

.chart_all_button {
  background-color: #fff;
  padding: 3px 8px;
  border: none;
  border-radius: 4px;
}

.chart_all_button.selected {
  background-color: #626577;
  color: #fff;
}

.user_areachart {
  width: 100%;
  height: 230px;
}

.areachart {
  width: 100%;
}

.chat_filter_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user_pie_chart {
  width: 30%;
  min-width: 290px;
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  gap: 6px;
}

.Totalnetworth p {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0px;
  color: black;
  font-family: var(--font-family);
}

.networth_amount {
  color: #059212;
}

.filter_button_style {
  margin: 0px 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family);
  text-decoration: none;
  border: none !important;
  background-color: inherit !important;
}

.dashboard_right {
  width: 26%;
  height: 100%;
  overflow-y: auto;
}

.dashboard_right::-webkit-scrollbar {
  display: none;
}

.Assets_count {
  width: 100%;
  background-color: var(--card-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  position: relative;
}

.Assets_report_heading {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0px;
  font-family: var(--font-family);

  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: var(--card-color);
  padding: 10px;
  margin: 0;
  border-radius: 10px;
}

.all_assets_report {
  width: 100%;
  height: 100%;
}

.assets_card {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 10px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.assets_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 5px;
}

.assets_card p {
  font-size: 15px;
  font-weight: 500;
  font-family: var(--font-family);
}

.assets_image {
  height: 40px;
  width: 40px;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  background-color: white;
}

/* user main dashboard end  */
.beneficiary-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.beneficiary-content {
  padding: 1.25em;
}

.cardtabs:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30%, 28%, 0.222);
  box-shadow: 1px 1px -15px rgba(0, 0, 0, 0.756);
}

.custom-text {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 10000;
}

.cardtabs:hover .custom-text {
  opacity: 1;
  pointer-events: auto;
}

.cardtabs {
  position: relative;
  border-radius: 10px;
  font-size: 0.9375em;
  width: 13em;
  background-color: white;
  box-shadow: 0 13px 27px -5px hsla(240, 30%, 28%, 0.141);
  transition: all ease 200ms;
  cursor: pointer;
  z-index: 100;
  height: 220px;
}

.card-text {
  z-index: 1;
  color: black;
  margin: 0.625em;
  text-align: center;
}

.card-img-top {
  border-radius: 10px 10px 0px 0px;
  object-fit: contain !important;
}

.card-title {
  font-size: 1.1625em;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  letter-spacing: 1.5px;
  padding: 35px 0;
}

.dashboard_top_button {
  width: 11.875em;
  padding: 0.3125em 0.625em;
  color: rgb(0, 0, 0);
  border: 0.125em solid #4aafff;
  background-color: #ffffff;
  text-align: center;
  margin-left: 10px;
  border-radius: 0.19em;
  margin-top: 10px;
  font-weight: 500;
}

.dashboard_top_button:hover {
  color: black;
  border: 0.125em solid #ff5a58;
  transition: 300ms;
}

.julimgcenter {
  overflow: hidden;
}

/* for dashboard button  */
.dashboard_all_btn {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 464px) {
  .dashboard_top_button {
    width: 100%;
    margin-right: 25px;
    padding: 0.3125em 0.625em;
    color: rgb(0, 0, 0);
    border: 0.125em solid #4aafff;
    background-color: #ffffff;
    text-align: center;
    margin-left: 1.25em;
    border-radius: 0.19em;
    margin-top: 1.25em;
    font-weight: 500;
  }

  /* for dashboard button  */
  .dashboard_all_btn {
    margin-top: 10px;
  }

  .test {
    width: 90%;
  }

  .test1 {
    width: 80%;
  }

  .beneficiary-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .beneficiary-content {
    padding: 1.25em;
  }

  .cardtabs {
    border-radius: 10px;
    height: 16.25em;
    background-color: white;
    box-shadow: 0 13px 27px -5px hsla(240, 30%, 28%, 0.141);
    transition: all ease 200ms;
    cursor: pointer;
  }

  .card-title {
    font-size: 1.0625em;
  }

  .card-text {
    font-size: 0.9375em;
    margin: 0.625em;
    text-align: center;
  }
}

@media (min-width: 465px) and (max-width: 620px) {
  /* for dashboard button  */
  .dashboard_all_btn {
    margin-top: 10px;
  }

  .gy-5 {
    --bs-gutter-y: 0.23rem;
  }

  .col-2 {
    width: 30% !important;
  }

  .cardtabs {
    border-radius: 10px;
    height: 210px;
    background-color: white;
    box-shadow: 0 13px 27px -5px hsla(240, 30%, 28%, 0.141);
    transition: all ease 200ms;
    cursor: pointer;
  }

  .card-title {
    font-size: 13px;
  }

  .card-text {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 619px) and (max-width: 850px) {
  .gy-5 {
    --bs-gutter-y: 0rem;
  }

  .col-2 {
    width: 23% !important;
  }

  .cardtabs {
    border-radius: 10px;
    height: 210px;
    background-color: white;
    box-shadow: 0 13px 27px -5px hsla(240, 30%, 28%, 0.141);
    transition: all ease 200ms;
    cursor: pointer;
  }

  .card-title {
    font-size: 13px;
  }

  .card-text {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 850px) and (max-width: 1120px) {
  .gy-5 {
    --bs-gutter-y: 0rem;
  }

  .col-2 {
    width: 20% !important;
  }

  .cardtabs {
    border-radius: 10px;
    height: 210px;
    background-color: white;
    box-shadow: 0 13px 27px -5px hsla(240, 30%, 28%, 0.141);
    transition: all ease 200ms;
    cursor: pointer;
  }

  .card-title {
    font-size: 13px;
  }

  .card-text {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (max-width: 592px) {
  .alterbox_design {
    position: fixed;
    right: 0;
    height: 90px;
    bottom: 0;
    margin: 10px;
    z-index: 10000;
    background-color: red;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
}

/* Alert box design  */
.alterbox_design {
  position: fixed;
  right: 0;
  height: 90px;
  bottom: 0;
  margin: 10px;
  z-index: 10000;
  background-color: red;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.alertbox_main {
  margin-left: 10px;
  background-color: white;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  height: 90px;
  flex-direction: row;
  align-items: center;
}

.alert_box_status {
  font-size: 12px;
  background-color: rgb(255, 104, 16);
  border-radius: 5px;
  padding: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  color: white;
  margin-left: 10px;
  font-weight: 300;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.alert_icon {
  height: 100%;
  width: 20%;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.alert_logo {
  width: 40px;
  font-size: 60px;
}

.alert_box_content {
  width: 70%;
  max-width: 400px;
  min-width: 220px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 85px;
}

.alert_box_heading {
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 0px !important;
  margin-bottom: 0px !important;
}

.alert_box_main_contant {
  font-size: 12px;
  line-height: 17px;
  height: 35px;
  margin-top: 10px !important;
  color: black;
}

.alert_button {
  background-color: white !important;
  border: none !important;
  font-weight: 500;
  color: rgb(61, 58, 255);
  margin-top: 0px;
}

.close_button {
  height: 100%;
  display: flex;
  width: 10%;
  justify-content: center;
  padding-top: 5px;
  min-width: 30px;
}

.alert_cancel_icon {
  background: rgb(234, 234, 234);
  height: 15px !important;
  width: 15px !important;
  font-size: 18px;
  padding: 3px !important;
  transition: all 300ms;
  border-radius: 50%;
  color: black;
  cursor: pointer;
}

.alert_cancel_icon:hover {
  background-color: #ffa6a5 !important;
}

@media (max-width: 992px) and (min-width: 776px) {
  .UserDetails {
    width: 31%;
    min-width: 200px;
    background-color: var(--card-color);
    height: 90px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
  }

  .Username_details {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family) !important;
    line-height: 22px;
    color: #626577;
    text-align: left;
    padding-bottom: 5px;
  }

  .date_time_details {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family-base) !important;
    color: #303867;
  }

  .User_action_btns {
    width: 59%;
    height: 140px;
    min-width: 230px;
    display: flex;
    flex-direction: column;
  }

  .user_dashbord_btn {
    width: 100%;
    display: flex;
    gap: 3%;
  }

  .add_owner_button {
    width: 48.5% !important;
  }

  .dashboard_action_btn {
    height: 40px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-base);
    color: #000000;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    background-color: var(--card-color);
    cursor: pointer;
    font-size: 13px;
    line-height: 12px;
    font-weight: 500;
  }

  .main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 85vh;
    overflow: hidden;
    padding: 0px 10px 0px 0px !important;
  }

  .Assets_report_heading {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family);
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: var(--card-color);
    padding: 10px 0px 10px 0px;
    margin: 0;
    border-radius: 0px;
  }

  .assets_card {
    background-color: #ffffff;
    margin: 0px;
    margin-bottom: 10px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .assets_data {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 5px;
  }

  .assets_data p {
    font-size: 12px;
    line-height: 14px;
    font-family: var(--font-family-base);
  }

  .Totalnetworth p {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0px;
    color: black;
    font-family: var(--font-family);
  }

  .user_chart_dash {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }

  .filter_button_style {
    margin: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    font-family: var(--font-family);
    text-decoration: none;
    border: none !important;
    background-color: inherit !important;
  }

  .chart_button {
    display: flex;
    gap: 10px;
    margin-left: 0px;
  }

  .chart_all_button {
    background-color: #fff;
    padding: 0px 5px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-family: var(--font-family-base);
  }

  .user_areachart {
    width: 100%;
    height: 230px;
    font-size: 13px;
    font-family: var(--font-family-base);
  }
}

@media (max-width: 776px) and (min-width: 578px) {
}
