.attorney_container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.attorney_registration_form {
  width: 420px;
  max-width: 1000px;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.attorney_registration_form_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e9dddd;
  border-radius: 5px 5px 0 0;
}
.attorney_registration_form_heading h1,
.joint_registration_form_heading h1 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding: 10px 0 2px;
}
.attorney_cancel_icon {
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  transition: all 300ms ease;
  margin: 5px 5px 0 0;
}

.attorney_cancel_icon:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.attorney_form_main {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px 18px;
}
.attorney_form {
  width: 100%;
  max-width: 980px;
  justify-content: space-evenly;
}

.attorney_form_btn_main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.attorney_form_btn {
  margin-top: 12px;
}

.attorney_form_btn button:hover {
  border: 0.125em solid #ff5a58;
  color: #000;
}

.attorney_form_btn button {
  transition: all 0.3s ease;
  background-color: unset;
  border: 0.125em solid #4aafff;
  padding: 5px 8px;
  border-radius: 2px;
  color: #000;
  font-weight: 500;
  width: 150px;
}

.attorney_registration_form::-webkit-scrollbar {
  width: 0px;
}

@media (max-width: 900px) and (min-width: 600px) {
  .attorney_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .attorney_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .attorney_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .attorney_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .joint_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .joint_form_left {
    width: 100%;
  }
  .joint_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .attorney_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .attorney_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .attorney_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .attorney_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .joint_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .joint_form_left {
    width: 100%;
  }
  .joint_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
}

/* for joint account css  */

.joint_registration_container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.joint_registration_form {
  width: 400px;
  max-width: 1000px;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 100%;
}

.joint_registration_form_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e9dddd;
  border-radius: 8px 8px 0 0;
}

.joint_registration_cancel_icon {
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  transition: all 300ms ease;
  margin: 5px 5px 0 0;
}
.joint_registration_cancel_icon:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.joint_registration_form_main {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px 18px;
  height: 400px;
  min-height: 300px;
  max-height: 424px;
  overflow-y: auto;
}

.joint_registration_form_main::-webkit-scrollbar {
  width: 4px;
}

.joint_registration_form_main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.joint_registration_form_main::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.joint_account_form {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  justify-content: space-between;
}

.joint_form_left {
  width: 100%;
}

.bene_address {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bene_address_half {
  width: 49%;
}

.joint_form_btn_main {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.joint_form_btn button:hover {
  border: 0.125em solid #ff5a58;
  color: #000;
}

.joint_form_btn button {
  transition: all 0.3s ease;
  background-color: unset;
  border: 0.125em solid #4aafff;
  padding: 3px 8px;
  border-radius: 2px;
  color: #000;
  font-weight: 500;
  width: 150px;
}

@media (max-width: 900px) and (min-width: 600px) {
  .joint_registration_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .joint_registration_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .joint_registration_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .joint_registration_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .joint_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .joint_registration_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .joint_registration_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .joint_registration_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .joint_registration_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .joint_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* for add owner form css  */

.owner_container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.owner_creation_form {
  width: 55%;
  max-width: 1000px;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 100%;
}

.owner_creation_form_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e9dddd;
  border-radius: 8px 8px 0 0;
}

.owner_creation_form_heading h1 {
  font-size: 20px;
  padding: 10px 0 2px;
}

.owner_creation_cancel_icon {
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  transition: all 300ms ease;
  margin: 5px 5px 0 0;
}
.owner_creation_cancel_icon:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.owner_creation_form_main {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px 18px;
}

.owner_creation_form_main::-webkit-scrollbar {
  width: 4px;
}

.owner_creation_form_main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.owner_creation_form_main::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.owner_form {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  justify-content: space-between;
}

.owner_form_left {
  width: 48%;
}

.bene_address {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bene_address_half {
  width: 49%;
}
.owner_form_right {
  width: 48%;
  height: fit-content;
  max-height: 364px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.owner_form_right::-webkit-scrollbar {
  width: 4px;
}

.owner_form_right::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.owner_form_right::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.owner_form_btn_main {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 45px;
}

.owner_form_btn button:hover {
  border: 0.125em solid #ff5a58;
  color: #000;
}

.owner_form_btn button {
  transition: all 0.3s ease;
  background-color: unset;
  border: 0.125em solid #4aafff;
  padding: 3px 8px;
  border-radius: 2px;
  color: #000;
  font-weight: 500;
  width: 150px;
}

@media (max-width: 900px) and (min-width: 600px) {
  .owner_creation_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .owner_creation_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .owner_creation_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .owner_creation_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .owner_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .owner_form_left {
    width: 100%;
  }
  .owner_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
  .owner_form_btn_main {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .owner_form_btn button {
    width: 100%;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .owner_creation_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .owner_creation_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .owner_creation_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .owner_creation_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .owner_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .owner_form_left {
    width: 100%;
  }
  .owner_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
  .owner_form_btn_main {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .owner_form_btn button {
    width: 100%;
  }
}

.css-1jf2wst-JoyFormControl-root {
  margin-bottom: 10px;
}

.css-9rix66 {
  margin-bottom: 10px;
}

.css-1a5wvyn-JoyFormHelperText-root {
  margin-top: 0 !important;
}
