.ichest {
  background-color: pink;
  width: "100vw";
  height: "100%";
  position: "absolute";
  z-index: "1000000";
  /* top: 500px; */
}

.allSignUp {
  width: 170px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.allSignUp ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.allSignUp a {
  padding: 5px;
  cursor: pointer;
  color: black;
}

.allSignUp a:hover {
  background-color: #f2f2f2;
}
.contact_us_form_main{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* contact us form css  */
.contact_us_form_detail {
  display: flex;
  width: 95%;
  max-width: 1200px;
  gap: 2%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 40px;
}
.contact_us_form_fields {
  width: 55%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.contact_us_form_img {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_us_form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 15px;
  width: 100%;
  max-width: 450px;
  min-width: 270px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.contact_us_form .contact_us_title {
  color: royalblue;
  margin: 10px 0px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.contact_us_form input, .contact_us_form .contact_us_select {
  outline: 0;
  border: 1px solid rgb(219, 213, 213);
  padding: 8px 14px;
  border-radius: 8px;
  width: 100%;
  height: 50px;
}

.contact_us_form textarea {
  border-radius: 8px;
  height: 100px;
  width: 100%;
  resize: none;
  outline: 0;
  padding: 8px 14px;
  border: 1px solid rgb(219, 213, 213);
}

.contact_us_form .contact_us_btn {
  align-self: flex-end;
  padding: 8px 20px;
  outline: 0;
  border: 0;
  margin: 10px 0px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  background-color: royalblue;
  color: #fff;
  cursor: pointer;
}

/* services file css  */
.services_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services_base {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.services_main {
  display: flex;
  width: 90%;
  max-width: 1150px;
  flex-direction: column;
}

.services_list_details {
  width: 100%;
}

.accordion_services {
  padding: 2rem 0 !important;
  background-color: #ebebeb !important;
}

.accordion_services .h2-heading {
  color: #000 !important;
}

.accordion_services .accordion-body {
  color: #000 !important;
}

.accordion_services .accordion-header button {
  color: #3b3b3b !important;
}

/* features file css  */
.features_section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features_section_main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.features_card {
  max-width: 370px;
  height: 325px;
  position: relative;
  display: block;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #eaf4fe !important;
  transition: all 300ms;
}

.features_card:hover {
  background-color: #dfe3ee !important;
  transform: scale(1.05);
}

.features_header {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.features_title {
  font-size: 1.42rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: rgba(17, 24, 39, 1);
}

.features_name {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: rgba(75, 85, 99, 1);
}

.image {
  display: block;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  object-fit: cover;
  background-color: royalblue;
}

.features_description {
  padding-top: 1rem;
  display: flex;
  line-height: 1.35rem;
  text-align: justify;
  color: rgba(107, 114, 128, 1);
}

.post-info {
  margin-top: 1.5rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.cr {
  display: flex;
  flex-direction: column-reverse;
}

.dt {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgba(75, 85, 99, 1);
}

.dd {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(107, 114, 128, 1);
}

@media (max-width:776px){
  .contact_us_form_detail{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .contact_us_form_fields{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact_us_form_img{
    display: none;
  }
}
