@media only screen and (min-width: 767px) {
    .loginpage {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .loginpage_login {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .logincard {
        max-height: 550px;
        width: 400px;
        height: 70vh;
        overflow-y: auto;
        background-Color: #ebebeb;
        border-radius: 0;
        transition: transform 0.3s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.145);
    }

    .login_submit_form {
        height: 94%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .logincard::-webkit-scrollbar {
        width: 4px;
    }

    .logincard::-webkit-scrollbar-track {
        background-color: #cccccc;
    }

    .logincard::-webkit-scrollbar-thumb {
        background-color: #b0b0b6;
        border-radius: 5px;
    }

    .logincard::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    .card-header {
        z-index: 999;
        height: 71px;
        border: none;
        background-color: #dcd2d2;
        padding: 10px;
        /* position: sticky; */
        top: 0;
        border-radius: 0px !important;
    }

    .logincard:hover {
        transform: scale(1.01);
        box-shadow: 4px 4px 10px gray;
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .logincard h3 {
        text-align: center;
        font-weight: bolder;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
        padding: 10px 0px 10px;
    }

    .login-card-details {
        color: rgb(0, 0, 0);
        padding: 10px;
    }

    .inputfiled::placeholder {
        color: rgb(0, 0, 0);
    }

    .forgot-btn {
        width: 100%;
        text-align: right;
    }

    .forgot-button {
        background-color: inherit;
        border: none;
        color: rgb(118, 124, 255);
        text-align: right;
        margin: 20px 10px;
        font-weight: 500;
    }

    .forgot-button:hover {
        background-color: inherit;
        border: none;
        color: rgb(79, 79, 97);
    }

    .loginbuttonspace {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding: 20px 0px;
    }

    .cler-button {
        width: 35%;
        border: 2px solid #4aafff;
        color: rgb(0, 0, 0);
        font-weight: 600;
        border-radius: 20px;
    }

    .cler-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .login-button {
        color: #181818;
        border: 2px solid #4aafff;
        font-weight: 600;
        width: 35%;
        border-radius: 20px;
    }

    .login-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .text-center {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        display: flex;
        color: rgb(255, 0, 0);
        border-radius: 5px !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .text-center-success {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        color: rgb(0, 131, 31);
        text-align: center;
        border-radius: 5px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

}

@media only screen and (min-width: 463px) and (max-width: 767px) {
    .loginpage {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .loginpage_login {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .logincard {
        max-height: 450px;
        height: 60vh;
        overflow-y: scroll;
        background-Color: #ebebeb;
        width: 350px;
        border-radius: 0px;
        transition: transform 0.3s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.145);
    }

    .card-header {
        z-index: 999;
        height: 60px;
        border: none;
        background-color: #dcd2d2;
        padding: 10px;
        /* position: sticky; */
        top: 0;
        border-radius: 0px !important;
    }

    .logincard h3 {
        text-align: center;
        font-weight: bolder;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
    }

    .login-card-details {
        color: rgb(0, 0, 0);

    }

    .inputfiled::placeholder {
        color: rgb(0, 0, 0);
    }

    .forgot-btn {
        width: 100%;
        text-align: right;
    }

    .forgot-button {
        background-color: inherit;
        border: none;
        color: rgb(118, 124, 255);
        text-align: right;
    }

    .forgot-button:hover {
        background-color: inherit;
        border: none;
        color: rgb(79, 79, 97);
    }

    .loginbuttonspace {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    .cler-button {
        width: 40%;
        color: #000;
        border: 2px solid #4aafff;
        font-weight: 400;
        border-radius: 20px;
    }

    .cler-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .login-button {
        color: #000;
        border: 2px solid #4aafff;
        font-weight: 400;
        width: 40%;
        border-radius: 20px;
    }

    .login-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .text-center {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        display: flex;
        color: rgb(255, 0, 0);
        border-radius: 5px !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .text-center-success {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        color: rgb(0, 131, 31);
        text-align: center;
        border-radius: 5px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

}

@media only screen and (max-width: 462px) {
    .login_page_btns{
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;

    }
    .show_password_btn{
        width: 100%;
    }
    .loginpage {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .loginpage_login {
        margin-top: 56px;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .logincard {
        max-height: 450px;
        height: 60vh;
        overflow-y: scroll;
        background-Color: #ebebeb;
        width: 350px;
        border-radius: 0px;
        transition: transform 0.3s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.145);
    }

    .card-header {
        z-index: 999;
        height: 60px;
        border: none;
        background-color: #dcd2d2;
        padding: 10px;
        /* position: sticky; */
        top: 0;
        border-radius: 0px !important;
    }

    .logincard h3 {
        text-align: center;
        font-weight: bolder;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
    }

    .login-card-details {
        color: rgb(0, 0, 0);
    }

    .inputfiled::placeholder {
        color: rgb(0, 0, 0);
    }

    .forgot-btn {
        width: 100%;
        text-align: right;
    }

    .forgot-button {
        background-color: inherit;
        border: none;
        color: rgb(118, 124, 255);
        text-align: right;
    }

    .forgot-button:hover {
        background-color: inherit;
        border: none;
        color: rgb(79, 79, 97);
    }

    .loginbuttonspace {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    .cler-button {
        width: 40%;
        color: #000;
        border: 2px solid #4aafff;
        font-weight: 400;
        border-radius: 20px;
    }

    .cler-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .login-button {
        color: #000;
        border: 2px solid #4aafff;
        font-weight: 400;
        width: 40%;
        border-radius: 20px;
    }

    .login-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .text-center {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        display: flex;
        font-size: 13px;
        color: rgb(255, 0, 0);
        border-radius: 5px !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .text-center-success {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        color: rgb(0, 131, 31);
        text-align: center;
        border-radius: 5px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
}














/* From Uiverse.io by micaelgomestavares */
.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    min-width: 270px;
    border-radius: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form button {
    align-self: flex-end;
}

.flex-column>label {
    color: #151717;
    font-weight: 600;
}

.inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.input {
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 15px;
    height: 100%;
}

.input:focus {
    outline: none;
}

.inputForm:focus-within {
    border: 1.5px solid #2d79f3;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.flex-row>div>label {
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.span {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
}

.button_submit_login {
    margin: 20px 0 10px 0;
    background-color: #ffffff;
    border: none;
    color: black;
    border: solid 1px lightgray;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    transition: all 300ms;
}

.button_submit_login:hover {
    background-color: #f1f2ff;
}

.p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
}

/*   
  .btn {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    border: 1px solid #ededef;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  
  .btn:hover {
    border: 1px solid #2d79f3;
    ;
  } */



.login_heading {
    width: 100%;
    /* background-color: #dcdce2; */
    display: flex;
    height: 50px;
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 20px;
}

.login_heading p {
    width: 100%;
    color: black;
    text-align: center;
    font-size: 25px;
    padding: 10px 0px;
    font-weight: 600;
}

.login_cancel_icon {
    display: flex;
    justify-content: flex-end;
}

.cancel_icon_login {
    width: 20px;
    height: 20px;
    padding: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 50%;
    color: rgb(0, 0, 0);
    transition: all 300ms ease;
    margin-right: 10px;
    /* margin: 5px 5px 0 0; */
}

.cancel_icon_login:hover {
    background-color: rgb(194, 194, 194);
    color: rgb(0, 0, 0);
}