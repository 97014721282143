.otppage-mane{
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}
.otppage-card{
    padding: 10px;
    width: 270px;
    background-color: rgb(236, 236, 236);
}
.otppage-otpdesign{
    display: flex;
    margin: 0px 10px ;
    justify-content: center;
}
.otppage-otpdesign input{
    margin: 3px;
}


.otpvalidate_page{
    width: 100%;
    margin-top: 10px;
    background-color: rgb(236, 236, 236);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.otp_heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}
.mainHeading {
    font-size: 1.1em;
    color: rgb(15, 15, 15);
    font-weight: 700;
    margin-bottom: 20px;
  }

  .otpSubheading {
    font-size: 0.8em;
    color: black;
    line-height: 17px;
    text-align: center;
  }


.otppage-button{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

  .verifyButton {
    width: 80%;
    height: 30px;
    border: none;
    background-color: rgb(127, 129, 255);
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    transition-duration: .2s;
    margin-bottom: 20px;
  }
  
  .verifyButton:hover {
    background-color: rgb(144, 145, 255);
    transition-duration: .2s;
  }


  .resendNote {
    font-size: 0.7em;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .resendBtn {
    background-color: transparent;
    border: none;
    color: rgb(127, 129, 255);
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 10px;
  }