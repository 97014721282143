* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
main {
  /* border: 10px solid red; */
  
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.sideplusmain{
  display: flex; 
  gap: 0;
  box-sizing: border-box;
  margin-top: 64px;
}


/* Sidebar */
.sidebar {
  /* margin-top: 14px; */
  background: rgb(232,232,232);
  position: relative;
  color: rgb(0, 0, 0);
  overflow-y: scroll;
  z-index: 9999;
  /* min-height: 100vh; */

}
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #888;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: rgb(0, 0, 0);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}
.link:hover {
  border-right: 4px solid white;
  /* background: rgb(45, 51, 89); */
  background: white;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
/* .active {
  border-right: 4px solid white;
  background: rgb(222 226 255);
   background: white; 
} */
.link_text {
  white-space: nowrap;
  font-size: 15px; 
}

.menu {
  display: flex;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 30px;
  text-decoration: none;
  /* border-bottom: #fff 0.5px solid; */
}


.inputbox {
  width: 370px;
}

 .td{
  border: 2px;
  padding: 5px;
  width: 50px;
} 

 table{
  min-width: 650px;
  border-collapse: separate;
  border-spacing: 0px 2px;
 }

 @media (max-width:800px) {
  .sidebar {
    /* margin-top: 14px; */
    background: rgb(232,232,232);
    color: rgb(0, 0, 0);
    /* position: fixed; */
    height:100vh;
    overflow-y: scroll;
    z-index: 10;
    padding-bottom: 20px;
  }
  main {
  /* padding: 10px; */
  /* margin-left: 30px; */
}
}