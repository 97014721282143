.beneficiary_signup {
  padding-top: 50px;
  height: 90%;
  width: 98vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginpage1 {
  min-width: 350px;
  max-height: 780px;
}
.beneficiary_login_popup {
  /* position: fixed; */
  z-index: 999;
  background-color: rgba(33, 33, 33, 0.529);
  width: 100vw;
  height: 100vh;
}

.logincard1 {
  border-radius: 5px;
  height: 100%;
  max-height: 650px;
  overflow-y: auto;
  width: 500px;
  min-width: 350px;
  border: none;
  /* background-color: #ebebeb; */
  background-color: #ffffff;
}
.inputfiled1 {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.logincard1::-webkit-scrollbar {
  width: 4px;
}

.logincard1::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.logincard1::-webkit-scrollbar-thumb {
  background-color: #b0b0b6;
  border-radius: 5px;
}

.logincard1::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.card-header1 {
  text-align: center;
  padding-top: 4%;
  text-transform: uppercase;
  border: none;
  background-color: #dcd2d2;
  padding: 10px;
  position: sticky;
  height: 60px !important;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logincard1 h3 {
  padding: 0 !important;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}

.login-card-details1 {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.inputfiled1::placeholder {
  color: rgb(0, 0, 0);
}

.forgot-btn1 {
  width: 100%;
  text-align: right;
}

.forgot-button1 {
  background-color: inherit;
  border: none;
  color: rgb(220, 222, 255);
  text-align: right;
}

.forgot-button1:hover {
  background-color: inherit;
  border: none;
  color: rgb(79, 79, 97);
}

.loginbuttonspace1 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.cler-button1 {
  width: 40%;
  font-size: 16px;
  color: #181818;
  background-color: #ffffff;
  border: 2px solid #4aafff;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  text-transform: capitalize;
}

.cler-button1:hover {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 2px solid #ff5a58;
}

.login-button1 {
  width: 40%;
  font-size: 16px;
  color: #181818;
  background-color: #ffffff;
  border: 2px solid #4aafff;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  text-transform: capitalize;
}

.login-button1:hover {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 2px solid #ff5a58;
}

/* beneficiary new form css  */

.beneficiary_container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.beneficiary_registration_form {
  width: 55%;
  max-width: 1000px;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 100%;
}

.beneficiary_registration_form_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e9dddd;
  border-radius: 8px 8px 0 0;
}

.beneficiary_registration_form_heading h1 {
    font-size: 20px;
    padding: 10px 0 2px;
}

.beneficiary_registration_cancel_icon {
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  transition: all 300ms ease;
  margin: 5px 5px 0 0;
}
.beneficiary_registration_cancel_icon:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.beneficiary_registration_form_main {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px 18px;
}

.beneficiary_registration_form_main::-webkit-scrollbar {
  width: 4px;
}

.beneficiary_registration_form_main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.beneficiary_registration_form_main::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.beneficiary_account_form {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  justify-content: space-between;
}

.beneficiary_form_left {
  width: 48%;
}

.bene_address {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bene_address_half {
  width: 49%;
}
.beneficiary_form_right {
  width: 48%;
  height: fit-content;
  max-height: 364px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.beneficiary_form_right::-webkit-scrollbar {
  width: 4px;
}

.beneficiary_form_right::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.beneficiary_form_right::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.beneficiary_form_btn_main {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 45px;
}

.beneficiary_registration_form_btn button:hover {
  border: 0.125em solid #ff5a58;
  color: #000;
}

.beneficiary_registration_form_btn button {
  transition: all 0.3s ease;
  background-color: unset;
  border: 0.125em solid #4aafff;
  padding: 3px 8px;
  border-radius: 2px;
  color: #000;
  font-weight: 500;
  width: 150px;
}

@media (max-width: 900px) and (min-width: 600px) {
  .beneficiary_registration_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .beneficiary_registration_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .beneficiary_registration_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .beneficiary_registration_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .beneficiary_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .beneficiary_form_left {
    width: 100%;
  }
  .beneficiary_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
  .beneficiary_form_btn_main {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .beneficiary_registration_form_btn button {
    width: 100%;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .beneficiary_registration_form_main {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 18px;
    min-height: 300px;
    max-height: 424px;
    overflow-y: auto;
  }
  .beneficiary_registration_form_main::-webkit-scrollbar {
    width: 4px;
  }

  .beneficiary_registration_form_main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  .beneficiary_registration_form_main::-webkit-scrollbar-track {
    background-color: lightgrey;
  }
  .beneficiary_account_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .beneficiary_form_left {
    width: 100%;
  }
  .beneficiary_form_right {
    width: 100%;
    height: fit-content;
    max-height: 660px;
    min-height: 300px;
    overflow-y: hidden;
  }
  .beneficiary_form_btn_main {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .beneficiary_registration_form_btn button {
    width: 100%;
  }
}
