

.legacy_details_modal{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.legacy_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.legacy_close_button{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: all 300ms;
}
.legacy_close_button:hover{
    background-color: rgb(185, 185, 185);
}
.legacy_detail_card{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    flex-direction: row;
    box-shadow: none !important;
}
.legacy_popup_left, .legacy_popup_right{
    width: 49%;
}

.legacy_detail_row {
    font-size: 13px;
    font-family: var(--font-family-base) !important;
    display: flex;
    min-width: 110px;
    flex-direction: row;
}

.legacy_detail_row strong {
    color: black;
    margin-right: 10px;
}
.legacy_notification{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.empty_circle.completed{
    width: 15px;         
    height: 15px; 
    margin: 0px 10px;
    border-radius: 50%;  
    border: 4px solid rgb(165, 255, 172);
    background-color: rgb(0, 202, 0); 
}
.empty_circle.incomplete{
    width: 15px;         
    height: 15px; 
    margin: 0px 10px;
    border: 4px solid rgb(255, 165, 165);
    border-radius: 50%;  
    background-color: rgb(255, 0, 0); 
}