.page_not_found{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}
.page_not_found_main{
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.page_not_found_gif {
    background-image: url('./../../public/img/page_not_found.gif');
    background-size: cover;
    background-position: center;
    width: 700px;
    height: 500px;
  }
.page_not_found_content{
    width: 300px;
}
.page_not_found_content h3{
    font-size: 40px;
    font-weight: 600;
    font-family: cursive;
}
.page_not_found_content h4{
    font-size: 15px;
    font-weight: 500;
    font-family: monospace;
    margin-bottom: 20px;
}
.page_not_found_back_home {
    background-color: #21a961;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: white;
}
.page_not_found_back_home:hover{
    color: white;
}
@media screen and (max-width:920px){
    .page_not_found {
        width: 100vw;
        height: 100vh;
        min-height: 676px;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        overflow-y: scroll;
    }
    .page_not_found_main {
        width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .page_not_found_gif {
        background-image: url('./../../public/img/page_not_found.gif');
        background-size: cover;
        background-position: center;
        width: 430px;
        height: 277px;
    }                   
}
