.popup-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.user_diary_add_note{
    width: 100%;
}
.popup_btn{
    position:fixed;
    display: flex;
    justify-content: flex-end;
    z-index: 100000;
    border: none;
}