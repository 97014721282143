.footer_section_base {
  background-color: #1a212a;
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.footer_section_main {
  width: 90%;
  display: flex;
  justify-content: space-between;
  max-width: 1150px;
}

.footer_section_column {
  width: 20%;
  padding: 10px;
}

.footer_section_contact {
  display: flex;
  flex-direction: column;
}

.footer_section_column {
  color: #fff;
}

.footer_section_column h4 {
  color: #fff;
  margin-bottom: 15px;
}

.footer_section_contact .phone_email {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_section_contact .phone_email:hover {
  color: rgb(21, 0, 255);
}

.footer_icon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.footer_icon_main {
  width: 100%;
}

.footer_all_icon {
  font-size: 28px;
  color: #fff;
}

.linkedIn_icon:hover {
  color: #0077b5;
}

.facebook_icon:hover {
  color: #1877f2;
}

.twitter_icon:hover {
  color: #1da1f2;
}

.youtube_icon:hover {
  color: #ff0000;
}

.footer_section_address {
  width: 30% !important;
}

.footer_section_address p {
  color: #fff;
}

.footer_links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer_all_links {
  color: #fff;
  text-decoration: none;
  display: block;
  font-size: 17px;
}

.footer_all_links:hover {
  color: #0077b5;
  text-decoration: none;
}

.newsletter {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.subscribe {
  width: 100%;
}

.subscribe .subscribe_bt {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background: #1d65c9;
  padding: 3px 10px;
  border-radius: 3px;
}

.subscribe .subscribe_bt:hover {
  margin-bottom: 2px;
  text-decoration: underline;
}

@media (max-width: 668px) {
  .footer_section_main {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1150px;
  }

  .footer_section_column {
    width: 50%;
    padding: 10px;
  }

  .footer_section_address {
    width: 50% !important;
  }
}
