.credit_card_base {
  display: flex;
  justify-content: center;
  width: 99.5%;
}

.credit_card_main {
  width: 80%;
}

.credit_card_body {
  overflow: auto;
  height: fit-content;
  min-height: 50px;
  max-height: 100vh;
}

.credit_card_table {
  width: 100%;
  border-collapse: collapse !important;
}

.credit_card_table thead tr th {
  border: 1px solid black;
  padding: 5px;
  background-color: #f2f2f2;
}

.credit_card_table tbody tr td {
  border: 1px solid black;
  padding: 5px;
}

.credit_card_add_more_button {
  height: 30px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #4aafff;
  border: none;
}

.credit_card_remove_button {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgb(255, 74, 74);
  border: none;
}

@media (max-width: 900px) and (min-width: 600px) {
  .credit_card_main {
    width: 98%;
    margin-right: 9px;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .credit_card_main {
    width: 98%;
    margin-right: 9px;
  }
}
