.beneficiary_list_base {
  display: flex;
  justify-content: center;
  width: 100%;
}

.beneficiary_list_main {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.beneficiary_heading {
  margin-top: 20px;
}

.beneficiary_container {
  width: 100%;
  max-width: fit-content;
}

.beneficiary_details_cards {
  width: 100%;
  overflow-x: auto;
}
.beneficiary_detail_card {
  width: 100%;
  display: flex;
  border-bottom: 1px solid lightgray;
  justify-content: flex-start;
  align-items: center;
  box-shadow: none !important;
}

.beneficiary_row {
  font-size: 13px;
  font-family: var(--font-family-base) !important;
  display: flex;
  min-width: 170px;
  flex-direction: column;
}

.beneficiary_row strong {
  font-size: 15px;
  color: black;
}

.beneficiary_row_data {
  color: rgb(71, 71, 71);
}

.beneficiary_row_data_name {
  font-size: 14px !important;
}

.beneficiary_row_action_btn{
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 250px;
}

.beneficiary_row_Action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  font-family: var(--font-family-base);
  border: 0.5px solid lightgray;
  padding: 2px 10px;
  border-radius: 8px;
  text-decoration: none;
  gap: 10px;
  width: 135px;
  transition: all 400ms;
}
.beneficiary_row_Action:hover {
  gap: 15px;
  background-color: rgb(211, 217, 255);
  font-size: 14px !important;
  font-size: var(--font-family-base);
  text-decoration: none;
}

.beneficiary_row_Action_delete_edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: var(--font-family-base);
  border: 0.5px solid lightgray;
  padding: 2px 10px;
  border-radius: 8px;
  text-decoration: none;
  gap: 10px;
  width: 45px;
  transition: all 400ms;
}

.beneficiary_row_Action_delete_edit:hover {
  gap: 15px;
  background-color: rgb(211, 217, 255);
  font-size: 14px !important;
  font-size: var(--font-family-base);
  text-decoration: none;
}
