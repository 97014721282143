.signup {
  height: 90%;
  width: 98vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginpage {
  min-width: 350px;
  /* max-height: 780px; */
}

.regcard {
  border-radius: 0;
  height: 100%;
  max-height: 780px;
  overflow-y: auto;
  width: 500px;
  min-width: 350px;
  border: none;
  background-color: #ebebeb;
}

.regcard::-webkit-scrollbar {
  width: 4px;
}

.regcard::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.regcard::-webkit-scrollbar-thumb {
  background-color: #b0b0b6;
  border-radius: 5px;
}

.regcard::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.signuppage {
  display: flex;
  height: 100vh;
  justify-content: right;
}

.cardheading {
  text-align: center;
  padding-top: 4%;
  text-transform: uppercase;
  border: none;
  background-color: #dcd2d2;
  padding: 10px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardheading h3 {
  padding: 0 !important;
}

.heading {
  font-weight: 800;
  font-family: "Candara";
  color: rgb(0, 0, 0);
}

.textfield {
  /*margin: 3%;*/
  color: rgb(0, 0, 0);
  caret-color: rgb(0, 0, 0);
}

.inputfiled {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-color: #ebebeb;
  color: rgb(0, 0, 0);
}

.page {
  padding-top: 60px;
  display: flex;
  float: right;
  height: 100vh;
  /* width: 40%; */
}

.headingname {
  margin: 0;
  font-weight: bold;
}

.cler-button {
  width: 35%;
  border: 2px solid #4aafff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 20px;
}

.cler-button:hover {
  border: 2px solid #ff5a58;
  color: rgb(0, 0, 0);
}

.login-button {
  border: 2px solid #4aafff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  width: 35%;
  border-radius: 20px;
}

.login-button:hover {
  border: 2px solid #ff5a58;
  color: rgb(0, 0, 0);
}

.loginbuttonspace {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 0px;
}

/* new UI  */
.login-popup {
  position: fixed;
  z-index: 999;
  background-color: rgba(33, 33, 33, 0.529);
  width: 100vw;
  /* height: 100vh; */
}

.banrakas {
  max-width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  margin-bottom: 10px;
}

.stepper_form {
  height: 450px;
  padding-bottom: 30px;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 15px !important;
  color: #000000DE;
}

.css-8wcvy5-MuiStep-root {
  height: 25px;
}

.css-t1ccx1-MuiStepper-root {
  display: flex;
  height: 25px;
  gap: 12px;
}
@media (max-width:992px){
  .css-t1ccx1-MuiStepper-root {
    display: flex;
    height: 45px;
    gap: 12px;
  }

  .signup_card {
    max-width: 650px;
    width: 90% !important;
    min-width: 270px !important;
    height: 550px !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    overflow-y: scroll;
    padding: 20px;
    /* border: 0.5px solid gray */
  }
}

.css-i4bv87-MuiSvgIcon-root {
  height: 20px !important;
  width: 20px !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-top: 3px;
}

.signup_submit_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signup_submit_form_design {
  height: 356px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.signup_card_button {
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.back_button {
  margin: 0px !important;
  width: 150px;
  border-radius: 10px !important;
}

.next_button {
  margin: 0px !important;
  width: 150px;
  border-radius: 10px !important;
}

.arrow_icon {
  margin-left: 25px;
  font-weight: 900;
  font-size: 20px;
}

.signup_card {
  max-width: 650px;
  width: 100%;
  height: 530px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  overflow-y: scroll;
  padding: 20px;
  /* border: 0.5px solid gray */
}

.signup_cancel_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_form_card_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.signup_heading {
  width: 100%;
  /* background-color: #dcdce2; */
  display: flex;
  height: 50px;
  justify-content: center;
  margin-bottom: 20px;
}
.signup_heading p {
  width: 100%;
  color: black;
  text-align: center;
  font-size: 25px;
  padding: 10px 0px;
  font-weight: 600;
}
.cancel_icon {
  width: 20px;
  height: 20px;
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  transition: all 300ms ease;
  margin-right: 10px;
  /* margin: 5px 5px 0 0; */
}

.cancel_icon:hover {
  background-color: rgb(194, 194, 194);
  color: rgb(0, 0, 0);
}
