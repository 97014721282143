/*--------------------------------------------------------------------- File Name: responsive.css ---------------------------------------------------------------------*/


/*------------------------------------------------------------------- 991px x 768px ---------------------------------------------------------------------*/

@media only screen and (min-width: 768px) and (max-width: 991px) {

	.header-search {
		padding: 15px 0px;
	}
	.header_left{
		width: 50%;
	}
	.banner_main{
		padding-left: 63px;
		padding-top: 47px;
	}
	
	

}


/*------------------------------------------------------------------- 767px x 599px ---------------------------------------------------------------------*/

@media only screen and (min-width: 599px) and (max-width: 767px) {
	.logo {
		text-align: center;
	}
	.header_left{
		width:50%;
	}
	
	.cart-content-right {
		padding-bottom: 5px;
	}

	.mg {
		margin: 0px 0px;
	}

	.menu-area-main {
		height: 256px;
		overflow-y: auto;
	}

	.megamenu>.row [class*="col-"] {
		padding: 0px;
	}

	.menu-area-main .megamenu .men-cat {
		padding: 0px 15px;
	}

	.menu-area-main .megamenu .women-cat {
		padding: 0px 15px;
	}

	.menu-area-main .megamenu .el-cat {
		padding: 0px 15px;
	}

	.mean-container .mean-nav ul li a.mean-expand {
		height: 19px;
	}

	.category-box.women-box {
		display: none;
	}

	.cart-box {
		display: inline-block;
		margin: 0px 30px;
	}

	.wish-box {
		float: none;
		margin: 0px 30px;
		display: inline-block;
	}

	.menu-add {
		display: none;
	}

	.category-box {
		display: none;
	}

	.mean-container .mean-nav ul li ol {
		padding: 0px;
	}

	.mean-container .mean-nav ul li a {
		padding: 10px 20px;
		width: 94.8%;
	}

	.mean-container .mean-nav ul li li a {
		width: 92%;
		padding: 1em 4%;
	}

	.mean-container .mean-nav ul li li li a {
		width: 100%;
	}

	.header-search {
		padding: 15px 0px;
	}

	#collapseFilter.d-md-block {
		padding: 30px 0px;
	}
}


/*------------------------------------------------------------------- 599px x 280px ---------------------------------------------------------------------*/

@media only screen and (min-width: 280px) and (max-width: 599px) {
	.cart-content-right {
		padding-bottom: 5px;
	}

	.megamenu>.row [class*="col-"] {
		padding: 0px;
	}

	.menu-area-main .megamenu .men-cat {
		padding: 0px 15px;
	}

	.menu-area-main .megamenu .women-cat {
		padding: 0px 15px;
	}

	.menu-area-main .megamenu .el-cat {
		padding: 0px 15px;
	}

	.mean-container .mean-nav ul li a {
		padding: 1em 4%;
		width: 92%;
	}

	.mean-container .mean-nav ul li li a {
		width: 90%;
		padding: 1em 5%;
	}

	.mean-container .sub-full.megamenu-categories ol li a {
		padding: 5px 0px;
		text-transform: capitalize;
		width: 100%;
	}

	.megamenu .sub-full.megamenu-categories .women-box .banner-up-text a {
		width: auto;
		border: none;
		float: none;
	}

	.menu-area-main {
		height: 45px;
		overflow-y: auto;
	}

	.mean-container .mean-nav ul li a.mean-expand {
		top: 0;
	}
	.header_left{
		width: 100%;
	}
	/* .banner_taital{
		padding-top: 75px;

	} */
	.banner_main{
		padding: 20px;
	}

}

@media (min-width: 991px)  {
	.header_left {
		width: 50%;
		padding-top: 211px;
		padding-left: 80px;
	}
	.banner_img {
		width: 70%;
		float: right;
		padding-top: 130px;
		margin-right: 80px;
	}
	.test{
		/* margin-left: 35px; */
	}
	.test1{
		/* margin-left: 180px; */
		transition: width 0.5s ;
	}
	.header_left {
		width: 50%;
		padding-top: 130px;
		padding-left: 80px;
	}
	.titlepage h2::after {
		width: 58%;
	}

	.about-box .titlepage h2::after {
		width: 52%;
		right: -221px;
	}

	.Nursery-img .text-box h3 {
		padding: 111px 50px;
	}

	.contact .titlepage h2::after {
		left: -650px;
		width: 30%;
	}

	.logo {
		width: 20%;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 5px;
		padding-left: 15px;
		font-size: 14px;
		color: #ffffff;
	}

	.banner_taital {
		font-size: 41px;
	}

	.btn_main {
		padding-top: 20px;
       
	}

	.services_section {
		padding-top: 65px;
		padding-bottom: 15px;
	}

	.project_main {
		padding-top: 100px;
	}

	.footer_images_right {
		width: 32%;
	}

	.moremore_bt {
		margin-top: 0px;
       
	}

	.padding_left_0 {
		padding-left: 0px;
	}

	.mail_section {
		width: 100%;
	}


}

@media (min-width: 768px) and (max-width: 991px) {
	.banner_img {
		width: 70%;
		float: right;
		padding-top: 65px;
		margin-right: 80px;
	}
	.test{
		margin-left: 0;
	}
	.test1{
		/* margin-left: 180px; */
		transition: width 0.5s ;
	}
	.header_right {
		width: 100%;
		padding-top: 60px;
	} 
	.main-menu ul>li a {
		padding: 7px 6px;
	}
    .contact_bt {
		width: 170px;
        display: flex;
        margin-top: 20px;
		
	}

	.main-menu ul>li a {
		font-size: 14px;
	}


	.banner-main .carousel-caption h1 {

		font-size: 36px;
		padding-top: 0px;
	}

	.banner-main .carousel-caption p {

		margin-bottom: 7px;
		display: none;
	}

	.main {
		width: 15%;
	}

	.info_icon {
		min-width: 10%;
	}

	#main_slider a.carousel-control-prev {
		display: none;
	}

	#main_slider a.carousel-control-next {
		display: none;
	}

	.sporrt_text {
		margin-top: 0px;
		font-size: 24px;
	}

	.email_btn {
		padding: 41px;
	}

	.btn-primary {
		max-width: 144px;
	}

	.titlepage h2::after {
		width: 79%;
	}

	.about-box .titlepage h2::after {
		width: 73%;
		right: -88px;
	}

	.contact .titlepage h2::after {
		left: -403px;
		width: 41%;
	}

	.paddimg-right {
		padding-right: 15px;
	}

	.paddimg-left {
		padding-left: 15px;
	}


	.banner_taital {
		font-size: 30px;
		/* padding-top: 56px; */
		padding-top: 100px;
	}

	.banner_main {
		padding-left: 63px;
	}

	.banner_text {
		width: 100%;
		font-size: 14px;
	}
	.btn_main {
		display: flex;
		justify-content: center;
	}
	.contact_bt button {
		width: 100%;
		float: left;
		font-size: 16px;
		color: #181818;
		border: 2px solid #4aafff;
		text-align: center;
		padding: 10px 0px;
		border-radius: 40px;
		text-transform: uppercase;
		font-weight: bold;
		margin-top: -20px;
	}

	.services_section {
		padding-top: 30px;
		background-repeat: no-repeat;
		padding-bottom: 30px;
	}

	.services_taital {
		font-size: 26px;
	}

	.services_text {
		width: 100%;
		font-size: 14px;
		margin-left: 0px;
		margin-top: 0px;
	}

	.image_1 {
		padding-top: 30px;
	}

	.lorem_text {
		font-size: 15px;
	}

	.what_we_do_section_2 {
		margin-top: 20px;
	}

	.box_main {
		margin-top: 30px;
	}

	.project_main {
		padding-top: 60px;
	}

	.image_6 {
		left: -70px;
	}

	.fact_text {
		font-size: 14px;
	}

	.what_text_1 {
		width: 100%;
		font-size: 15px;
	}

	.footer_section {
		padding-top: 50px;
	}

	.about_text {
		padding-top: 40px;
	}

	.footer_images_left {
		width: 20%;
	}

	.footer_images_right {
		width: 20%;
	}

	.copyright_section {
		width: 90%;
	}

	.moremore_bt {
		margin-top: 0px;
		width: 160px;
	}

	.what_text {
		font-size: 15px;
	}

	.images_left {
		width: 49%;
	}

	.padding_left_0 {
		padding-left: 0px;
	}

	.mail_section {
		width: 100%;
	}


}

@media (min-width: 575px) and (max-width: 767px) {
	.banner_img {
		width: 70%;
		float: right;
		padding-top: 120px;
		margin-right: 80px;
	}
	.contact_bt button {
		width: 100%;
		float: left;
		font-size: 16px;
		color: #181818;
		border: 2px solid #4aafff;
		text-align: center;
		padding: 10px 0px;
		border-radius: 40px;
		text-transform: uppercase;
		font-weight: bold;
		margin-top: -20px;
	}
	.last {
		display: none;
	}
	.header_left {
		width: 50%;
		padding-top: 41px;
	}
	.banner-main .carousel-caption {
		top: 0%;
	}
	.titlepage h2 {
		font-size: 42px;
		display: block;
	}

	#main_slider a.carousel-control-prev {
		position: absolute;
		left: 25%;
		top: 64%;
		display: none;
	}

	#main_slider a.carousel-control-next {
		position: absolute;
		left: 50px;
		top: 64%;
		display: none;
	}

	.btn-primary {
		margin-top: 0px;
		margin-left: -7px;
	}

	.banner-main .carousel-caption h1 {
		font-size: 50px;
	}

	.titlepage h2::after {
		width: 88%;
	}

	.about-box .titlepage h2::after {
		width: 45%;
		right: -281px;
	}

	.sporrt_text {
		margin-top: 0px;
	}

	.Nursery-img .text-box h3 {
		font-size: 36px;
		padding: 126px 40px;
	}

	.main {
		width: 20%;
	}

	.contact .titlepage h2::after {
		left: -234px;
		width: 55%;
	}

	.banner_taital {
		font-size: 30px;
		padding-top: 45px;
	} 

	.banner_main {
		padding-left: 35px;
	}

	.banner_text {
		width: 100%;
		
		font-size: 14px;
	}

	.btn_main {
		display: flex;
	}

	.more_bt {
		width: 130px;

	}

	.contact_bt {
        margin-top: 20px;
		width: 130px;
	}

	.services_section {
		padding-top: 50px;
		background-repeat: no-repeat;
		padding-bottom: 0px;
	}

	.services_taital {
		font-size: 28px;
	}

	.services_text {
		width: 100%;
		font-size: 14px;
		margin-left: 0px;
		margin-top: 0px;
	}

	.image_1 {
		padding-top: 30px;
	}

	.lorem_text {
		font-size: 15px;
	}

	.what_we_do_section_2 {
		margin-top: 20px;
	}

	.box_main {
		margin-top: 30px;
	}

	.project_main {
		padding-top: 0px;
	}

	.image_6 {
		left: -70px;
	}

	.fact_text {
		font-size: 14px;
	}

	.what_text_1 {
		width: 100%;
		font-size: 15px;
	}

	.footer_section {
		padding-top: 50px;
	}

	.about_text {
		padding-top: 40px;
	}

	.footer_images_left {
		width: 20%;
	}

	.footer_images_right {
		width: 20%;
	}

	.copyright_section {
		width: 90%;
	}

	.padding_left_0 {
		padding-left: 0px;
	}

	.mail_section {
		width: 100%;
		padding-left: 20px;
	}

}


@media (max-width: 575px) {
	
	.last {
		display: none;
	}
	.header_right{
		display: none;
	}
	.header_left{
		width:100%;
	}
	.banner_taital{
		padding-top: 58px;

	}
	.banner_main{
		padding-left: 25px;
	}
	.carousel-caption {
		display: none;
	}

	.header {
		padding-top: 0px;
	}

	.btn-primary {
		max-width: 126px;
		margin-right: 2px;
		font-size: 14px;
	}

	.banner-main .carousel-caption h1 {
		font-size: 30px;
		line-height: 38px;
	}

	.titlepage h2 {
		font-size: 27px;
	}

	.sporrt_text {
		font-size: 23px;
		margin-top: 0px;
	}

	#main_slider a.carousel-control-prev {
		position: absolute;
		left: 175px;
		top: 90%;
		display: none;
	}

	#main_slider a.carousel-control-next {
		position: absolute;
		left: 236px;
		top: 90%;
		display: none;
	}

	.titlepage h2::after {
		width: 93%;
	}

	.about-box .titlepage h2::after {
		width: 238px;
		right: -50px;
	}

	.Gallery .titlepage h2 {
		font-size: 47px;
	}

	.Gallery .titlepage h2::after {
		width: 89%;
	}

	.paddimg-right {
		padding-right: 15px;
	}

	.paddimg-left {
		padding-left: 15px;
	}

	.Nursery-img .text-box h3 {
		font-size: 23px;
		padding: 30px 14px;

	}

	.contact .titlepage h2::after {

		left: -8px;
		width: 281px;
	}

	.footer .headinga span {
		font-size: 16px;

	}

	.menu-bottom {
		margin-bottom: 45px;
	}

	ul.link li {

		padding: 8px 12px;
		padding-bottom: 0px;
	}

	.logo {
		width: 45%;
	}
	.btn_main {
		display: flex;
		float: left;
		justify-content: center;    
	}
	.contact_bt button {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #181818;
    border: 2px solid #4aafff;
    text-align: center;
    padding: 10px 0px;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: -20px;
}

	.contact_bt {
		width: 140px;
		margin-top: 20px;
	}

	.more_bt {
		width: 140px;
	}

	.header_right {
		width: 100%;
		padding-top: 60px;
	}

	.services_section {
		background: #ffffff;
		padding-bottom: 0px;
		margin-top: 0px;
	}

	.services_taital {
		font-size: 24px;
	}

	.image_1 {
		padding-top: 30px;
	}

	.what_we_do_section_2 {
		margin-top: 0px;
	}

	.box_main {
		margin-top: 40px;
	}

	.project_main {
		padding-top: 0;
	}

	.image_6 {
		left: -60px;
	}

	.images_left {
		width: 100%;
	}

	.images_right {
		width: 100%;
	}

	.images_right {
		margin-top: 5px;
		margin-left: 0;
	}

	.what_taital {
		font-size: 21px;
	}

	.what_text_1 {
		width: 100%;
		font-size: 16px;
	}

	.image_11 {
		width: 50%;
	}

	.dummy_text {
		width: 100%;
	}

	.copyright_section {
		width: 90%;
	}

	.round_1 {
		display: none;
	}

	.round_2 {
		display: none;
	}

	.footer_section {
		padding-top: 50px;
	}

	.about_text {
		padding-top: 40px;
	}

	.social_icon {
		padding-bottom: 20px;
	}

	.padding_left_0 {
		padding-left: 0px;
	}


}