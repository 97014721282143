@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
:root{
	--font-family-heding: "Roboto Condensed", sans-serif;
	--font-family-base: "Roboto Flex", sans-serif;
}
body,
p {
	font-family: var(--font-family-base) !important;
	color: #000000;
	font-size: 16px;
	font: 400 1rem/1.625rem "Open Sans", sans-serif;
}

h1 {
	font-family: var(--font-family-heding);
	color: #223150;
	font-weight: 700;
	font-size: 2.5rem;
	line-height: 3.25rem;
	letter-spacing: -0.4px;
}

h2 {
	font-family: var(--font-family-heding);
	color: #223150;
	font-weight: 700;
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: -0.4px;
}

h3 {
	color: #223150;
	font-weight: 700;
	font-size: 1.75rem;
	line-height: 2.25rem;
	letter-spacing: -0.2px;
}

h4 {
	color: #223150;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	letter-spacing: -0.2px;
}

h5 {
	color: #223150;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

h6 {
	color: #223150;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.375rem;
}

.p-large {
	font-size: 16px !important;
	line-height: 1.75rem;
	text-align: justify;
}

.p-small {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.testimonial-text {
	font-style: italic;
}

.testimonial-author {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.li-space-lg li {
	margin-bottom: 0.5rem;
}

a {
	color: #5e6576;
	text-decoration: underline;
}

a:hover {
	color: #5e6576;
	text-decoration: underline;
}

.no-line {
	text-decoration: none;
}

.no-line:hover {
	text-decoration: none;
}

.read-more {
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.read-more .fas {
	margin-left: 0.625rem;
	font-size: 1rem;
	vertical-align: -8%;
}

.blue {
	color: #0092ff;
}

/* .bg-gray {
	background-color:#0092ff;
} */

.section-title {
	margin-bottom: 0.5rem;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
}

.btn-solid-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #0092ff;
	border-radius: 30px;
	background-color: #0092ff;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-reg:hover {
	background-color: transparent;
	color: #0092ff;
	/* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #0092ff;
	border-radius: 30px;
	background-color: #0092ff;
	color: #ffffff;
	font-weight: 600;
	width: 140px;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-lg:hover {
	background-color: transparent;
	/* color: #0092ff; */
	/* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #0092ff;
	border-radius: 30px;
	background-color: #0092ff;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-sm:hover {
	background-color: transparent;
	color: #0092ff;
	/* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-outline-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-reg:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-lg:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-sm:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.form-control-input,
.form-control-textarea {
	width: 100%;
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	padding-left: 1.5rem;
	border: 1px solid #cbcbd1;
	border-radius: 8px;
	background-color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	-webkit-appearance: none;
	/* removes inner shadow on form inputs on ios safari */
}

.form-control-textarea {
	display: block;
	height: 14rem;
	/* used instead of html rows to normalize height between Chrome and IE/FF */
}

.form-control-input:focus,
.form-control-textarea:focus {
	border: 1px solid #a1a1a1;
	outline: none;
	/* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-textarea:hover {
	border: 1px solid #a1a1a1;
}

.form-control-submit-button {
	display: inline-block;
	width: 100%;
	height: 3.25rem;
	border: 1px solid #0092ff;
	border-radius: 30px;
	background-color: #0092ff;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	cursor: pointer;
	transition: all 0.2s;
}

.form-control-submit-button:hover {
	border: 1px solid #0092ff;
	background-color: transparent;
	color: #0092ff;
}

/* .navbar_base {
	padding-top: 8px !important;
} */

/**********************/
/*     Navigation     */
/**********************/
.navbar {
	background-color: #6086fe !important;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
	z-index: 99999 !important;
}

.navbar .navbar-brand {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.navbar .logo-image img {
	/* width: 55px; */
	height: 55px;
}

.navbar .logo-text {
	color: #223150;
	font-weight: 700;
	font-size: 1.875rem;
	line-height: 1rem;
	text-decoration: none;
}

.offcanvas-collapse {
	position: fixed;
	top: 3.25rem;
	/* adjusts the height between the top of the page and the offcanvas menu */
	bottom: 0;
	left: 100%;
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	overflow-y: auto;
	visibility: hidden;
	background-color: #fcfafb;
	transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.offcanvas-collapse.open {
	visibility: visible;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.navbar .navbar-nav {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.navbar .nav-item .nav-link {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #5e6576;
	text-decoration: none;
	transition: all 0.2s ease;
}

.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
	color: #0092ff;
}

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
	animation: fadeDropdown 0.2s;
	/* required for the fade animation */
}

@keyframes fadeDropdown {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.navbar .dropdown-menu {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	border: none;
	background-color: #fcfafb;
}

.navbar .dropdown-item {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #5e6576;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
	text-decoration: none;
}

.navbar .dropdown-item:hover {
	background-color: #fcfafb;
	color: #0092ff;
}

.navbar .dropdown-divider {
	width: 100%;
	height: 1px;
	margin: 0.5rem auto 0.5rem auto;
	border: none;
	background-color: #d1d1d1;
}

/* end of dropdown menu */

.navbar .navbar-toggler {
	padding: 0;
	border: none;
	font-size: 1.25rem;
}


/******************/
/*     Header     */
/******************/

.col-xl-5 {
	flex: 0 0 auto;
	display: flex;
	width: 41.66666667%;
	align-items: center;
}

.col-xl-7 {
	height: 500px;
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
}

.img-fluid {
	max-width: 70%;
	min-width: 280px;
}

.login_buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

/* .img-fluid {
	max-width: 100%;
	height: 400px;
} */

/* .row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
} */
.col-lg-12 {
	flex: 0 0 auto;
	width: 100%;
	display: flex;
	gap: 15px;
	flex-wrap: wrap !important;
	justify-content: center;
}
.card-title1{
	font-size: 17px;
	font-weight: 500;
	font-family: var(--font-family-heding);
}
.homepage_heding{
	width: 100%;
	font-family: var(--font-family-heding) !important;
	display: flex;
	justify-content: flex-start !important;
}
.paragraph{
	font-size: 16px;
	font-family: var(--font-family-base);
	color: #000000;
}
.cards-1 .card {
	width: 30%;
	background-color: #EAF4FE !important;
	padding: 20px;
	border: none;
	transition: all 300ms;
}

.h2-heading {
	text-align: left;
}

.our_service p {
	text-align: justify;
	text-indent: 50px;
}

.modelcard {
	width: 48% !important;
}

.cards-1 .card:hover {
	background-color: #DFE3EE !important;
	transform: scale(1.05);
}

.card p {
	color: #062568;
}

.welcome_page {
	justify-content: flex-start !important;
}

.col-xl-5 {
	flex: 0 0 auto;
	display: flex;
	width: 45.666667%;
	align-items: center;
}

.col-xl-7 {
	height: 408px;
	width: 40%;
}

@media (max-width : 1201px) {
	.profile_image_card {
		width: 100%;
		height: 330px !important;
	}

	.patner_profile_image {
		height: 330px !important;
		width: 100%;
		object-fit: cover;
		object-position: top;
	}

	.Profile_card {
		gap: 3rem !important;
		margin-top: 3rem;
		width: 45% !important;
		min-width: 250px;
		height: 480px !important;
		border-radius: 20px;
		background: #a8b0f11f;
		position: relative;
		padding: 1.5rem;
		border: 2px solid #97b1f633;
		transition: 0.5s ease-out;
		overflow: visible;
	}

	.cards-1 .card {
		width: 30%;
		background-color: gainsboro;
		padding: 20px;
	}

	.col-xl-7 {
		height: 408px;
		width: 40%;
	}

	.col-xl-5 {
		flex: 0 0 auto;
		display: flex;
		width: 54.666667%;
		align-items: center;
	}
}
@media (max-width: 992px) {
	.accordion-1 {
		padding-top: 1.25rem !important;
		background-size: cover;
	}

	.row {
		margin-top: calc(-1* var(--bs-gutter-y));
		/* margin-right: 0px; */
		margin-left: calc(-.5* var(--bs-gutter-x));
	}

	.display_image {
		display: none !important;
	}

	.welcome_page {
		justify-content: center !important;
	}

	.img-fluid {
		max-width: 60%;
		min-width: 280px;
	}

	.col-xl-7 {
		height: 450px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.col-lg-6 {
		display: flex;
		flex: 0 0 auto;
		width: 100% !important;
		align-items: center;
		justify-content: flex-end;
	}

	.col-xl-5 {
		width: 100% !important;
	}

	.btn-solid-lg {
		margin: 10px 10px;
	}
}

@media (max-width :768px) {
	.home_page_content h2 {
		font-size: 32px;
		color: #000d72 !important;
		text-shadow: 2px 2px 4px #7a87ff;
		margin-bottom: 20px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-weight: 600px;
	}
	.home_page_content p {
		font-size: 16px;
		color: #000d72 !important;
		font-weight: 400;
		letter-spacing: 1px;
		text-shadow: 2px 2px 4px #696bf7;
		line-height: 22px;
		text-align: left;
		margin-bottom: 35px !important;
		/* margin-left: -10px !important; */
	}
	.tab_largescreen_view {
		display: none !important;
	}

	.service_page {
		display: flex;
		justify-content: space-between;
		flex-direction: column !important;
		width: 100%;
		align-items: center;
	}

	.service_details {
		width: 100% !important;
		padding: 20px;
	}

	.service_details p {
		line-height: 30px;
		font-size: 16px;
		font-family: var(--font-family-base) !important;
		color: black !important;
	}

	.service_image {
		border-radius: 0px 0px 80px 0px;
		width: 30%;
		min-width: 280px;
		overflow: hidden;

	}

	.service_image_srvice {
		width: 100%;
		min-width: 280px;
		transition: all 0.4s ease-in-out;
	}

	.service_image_srvice:hover {
		transform: scale(1.1);
	}










	.profile_image_card {
		width: 100%;
		height: 330px !important;
	}

	.patner_profile_image {
		height: 330px !important;
		width: 100%;
		object-fit: cover;
		object-position: top;
	}

	.Profile_card {
		margin: 1rem;
		width: 100% !important;
		height: 480px !important;
		border-radius: 20px;
		background: #c1c0fa29;
		position: relative;
		padding: 1.5rem;
		border: 2px solid #f7f9ffb4;
		transition: 0.5s ease-out;
		overflow: visible;
	}

	.homepage_main {
		display: flex;
		flex-direction: row;
		justify-content: center !important;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;
		width: 100%;
	}

	.homepage_details {
		text-align: center !important;
		width: 100% !important;
		min-width: 100% !important;
		padding: 20px;
	}

	.col-xl-7 {
		height: 350px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.col-xl-5 {
		width: 100% !important;
	}

	.cards-1 .card {
		width: 100% !important;
		background-color: #f3f6ff !important;
		padding: 20px !important;
		margin-top: 30px;
	}

	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

@media (max-width: 576px) {
	.home_page_content h2 {
		font-size: 32px;
		color: #3e24ff !important;
		text-shadow: 2px 2px 4px #b19dff;
		margin-bottom: 20px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-weight: 600px;
	}
	.home_page_content p {
		font-size: 16px;
		color: #3e24ff !important;
		font-weight: 400;
		letter-spacing: 1px;
		text-shadow: 2px 2px 4px #b19dff;
		line-height: 22px;
		text-align: left;
		margin-bottom: 35px !important;
		/* margin-left: -10px !important; */
	}
	.icest_card {
		width: 95% !important;
	}

	.key_assets_left,
	.key_assets_right {
		gap: 0px !important;
		width: 95% !important;
	}

	.home_page_content h2 {
		font-size: 25px !important;
		color: #000d72;
		text-shadow: 2px 2px 4px #7a87ff;
		margin-bottom: 20px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-weight: 600px;
	}

	.home_page_content p {
		font-size: 13px !important;
		color: #000d72;
		font-weight: 400;
		letter-spacing: 1px;
		text-shadow: 2px 2px 4px #b19dff;
		line-height: 22px;
		text-align: center !important;
		margin-bottom: 35px !important;
		/* margin-left: -10px !important; */
	}

	.card_design {
		display: flex !important;
		flex-direction: row !important;
		width: 100% !important;
		height: 50px !important;
		line-height: 20px;
	}

	.card_content {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.card_content h2 {
		line-height: 10px;
		font-size: 20px !important;
	}

	.card_content p {
		line-height: 10px;
		font-size: 10px !important;
	}

	.card_image {
		height: 50px !important;
		width: 50px !important;
	}

	.card_profile_image {
		height: 50px !important;
		width: 50px !important;
	}

	.homepage_main {
		display: flex;
		flex-direction: column-reverse !important;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;
	}

	.login_buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		width: 100%;
	}

	.btn-solid-lg {
		width: 90%;
		margin: 10px 0px !important;
	}

	.profile_image_card {
		width: 100% !important;
		height: 230px !important;
		display: flex;
		justify-content: center !important;
	}

	.patner_profile_image {
		height: 230px !important;
		width: 60% !important;
		object-fit: scale-down;
		object-position: top;
	}

	.Profile_card {
		margin-top: 2rem !important;
		margin: 0;
		width: 100% !important;
		height: 380px !important;
		border-radius: 20px;
		background: #a8b0f11f;
		position: relative;
		padding: 1.5rem;
		border: 2px solid #97b1f633;
		transition: 0.5s ease-out;
		overflow: visible;
	}

	.App_this {
		font-family: sans-serif;
		text-align: center;
		font-size: 11px !important;
		font-weight: 600;
		letter-spacing: 0px !important;
		line-height: 1.2;
		text-align: left;
		color: #1dff00;
	}

	.navbar-brand {
		margin-right: 0px;
	}

	.navbar-toggler {
		margin-right: 0px;
	}

	.homepage_main {
		display: flex;
		flex-direction: row;
		justify-content: center !important;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;
		width: 100%;
	}

	.homepage_details {
		text-align: center !important;
		width: 100% !important;
		min-width: 100% !important;
		padding: 20px;
	}

	.header .image-container {
		margin-top: 2rem !important;
	}

	.navbar_sub_slogan {
		font-weight: 400;
		font-size: 9px !important;
	}

	.navbar_slogan {
		font-weight: 500;
		font-size: 13px !important;
		letter-spacing: 0.5px;
		color: #062568;
		height: 40px;
		line-height: 15px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.navbar .logo-image img {
		height: 40px !important;
		width: 40px !important;
	}

	.copyright_section{
		width: 100% !important;
	}

	.col-xl-5 {
		width: 100% !important;
	}

	.cards-1 .card {
		width: 100% !important;
		background-color: #f3f6ff !important;
		padding: 20px !important;
		margin-top: 30px;
	}

	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.col-xl-7 {
		height: 280px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 406px) {

	.profile_image_card {
		width: 100% !important;
		height: 300px !important;
		display: flex;
		justify-content: center !important;
	}

	.patner_profile_image {
		height: 300px !important;
		width: 100% !important;
		object-fit: scale-down;
		object-position: top;
	}

	.Profile_card {
		margin-top: 2rem !important;
		margin: 0;
		width: 100% !important;
		height: 450px !important;
		border-radius: 20px;
		background: #a8b0f11f;
		position: relative;
		padding: 1.5rem;
		border: 2px solid #97b1f633;
		transition: 0.5s ease-out;
		overflow: visible;
	}
}

.homepage_main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	height: 100%;
}

.homepage_details {
	width: 48%;
	min-width: 300px;
	text-align: left;
}

.homepage_image {
	width: 45%;
	min-width: 250px;
}

.display_image {
	width: 30%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    justify-content: space-between;
    align-items: center;
}
.col-lg-6 {
	display: flex;
	flex: 0 0 auto;
	width: 50%;
	align-items: center;
	justify-content: flex-end;
}

.header {
	padding-top: 5rem;
	padding-bottom: 4em;
	/* background: url('../images/header-background.jpg') center center no-repeat; */
	background-size: cover;
	text-align: center;
}

.header .h1-large {
	margin-bottom: 1.75rem;
}

.header .p-large {
	margin-bottom: 2.25rem;
}

.header .image-container {
	margin-top: 5rem;
}


/********************/
/*     Services     */
/********************/
.cards-1 {
	padding-bottom: 2.25em;
}


.cards-1 .card-icon {
	width: 66px;
	height: 66px;
	margin-bottom: 1.875rem;
	border-radius: 8px;
	background-color: #ddedfb;
	text-align: center;
}

.cards-1 .card-icon .fas,
.cards-1 .card-icon .far {
	color: #0092ff;
	font-size: 2rem;
	line-height: 66px;
}

.cards-1 .card-icon.red {
	background-color: #fbe4e4;
}

.cards-1 .card-icon.red .fas,
.cards-1 .card-icon.red .far {
	color: #ef473d;
}

.cards-1 .card-icon.green {
	background-color: #e0f4ea;
}

.cards-1 .card-icon.green .fas,
.cards-1 .card-icon.green .far {
	color: #16c66d;
}

.cards-1 .card-body {
	padding: 0;
	text-align: justify;
}

.cards-1 .card-body p {
	margin-bottom: 1.125rem;
}

.cards-1 .read-more {
	color: #223150;
}

.cards-1 .read-more .fas {
	color: #223150;
}


/*********************/
/*     Details 1     */
/*********************/
.basic-1 {
	padding-top: 2.25rem;
	padding-bottom: 4.75rem;
	background: linear-gradient(rgb(255, 255, 255), #398cff);
}

.basic-1 .image-container {
	margin-bottom: 4rem;
}

.basic-1 h2 {
	margin-bottom: 1.75rem;
}

.basic-1 p {
	margin-bottom: 2rem;
}

.fundamental_idea {
	background-image: linear-gradient(to bottom right, #EAF4FE, #DFE3EE);
	border-radius: 10px;
	padding: 20px;
	text-align: justify;
	transition: background-image 600ms ease-in-out, transform 600ms ease-in-out;
}

.fundamental_idea p {
	color: #062568;
}

.fundamental_idea:hover {
	background-image: linear-gradient(to bottom right, #DFE3EE, #ACD6FE);
	transform: scale(1.02);
}

/*********************/
/*     Details 2     */
/*********************/
.basic-2 {
	padding-top: 4.5rem;
	padding-bottom: 9.5rem;
	/* background: url('../images/details-2-background.jpg') center center no-repeat; */
	background-size: cover;
}

.basic-2 .text-container {
	margin-bottom: 4rem;
}

.basic-2 h2 {
	margin-bottom: 1.75rem;
}

.basic-2 p {
	margin-bottom: 2rem;
}

.featurebackground{
	background-image: url(./../../public/img/backgroundimage.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.accordion-1 .h2-heading {
	margin-bottom: 1rem;
	color: #000000;
	text-align: center;
}

.accordion-1 .p-heading {
	margin-bottom: 4rem;
	color: #ffffff;
	text-align: center;
}

.accordion-1 .accordion {
	margin-bottom: 5rem;
}

.accordion-1 .accordion-item {
	margin-bottom: 2.5rem;
	border: none;
	border-radius: 0;
	background-color: transparent;
}

.accordion-1 .accordion-item:last-of-type {
	margin-bottom: 0;
}

.accordion-1 .accordion-icon {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 4px;
	text-align: center;
	background-color: #14be9f;
}

.accordion-1 .accordion-icon.blue {
	background-color: #1d79fb;
}

.accordion-1 .accordion-icon.purple {
	background-color: #dc63ff;
}

.accordion-1 .accordion-icon.orange {
	background-color: #ff6c02;
}

.accordion-1 .accordion-icon .fas {
	color: #ffffff;
	font-size: 1.25rem;
	line-height: 44px;
}

.accordion-1 .accordion-header {
	margin-left: 4.125rem;
	padding: 0.5rem 0 0.5rem 0;
	border: none;
	background-color: transparent;
}

.accordion-1 .accordion-header button {
	padding: 0;
	border: none;
	background-color: transparent;
	color: #000000;
	box-shadow: none;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.625rem;
	letter-spacing: -0.4px;
	text-align: left;
}

.accordion-1 .accordion-header button:after {
	display: none;
}

.accordion-1 .accordion-body {
	margin-left: 4.125rem;
	padding: 0.375rem 0 0 0;
	color: #000000;
	font-size: 16px;
	font-family: var(--font-family-base) !important;
}

.navbar-toggler-icon {
	margin-right: 10px;
}

.navbar-collapse {
	background-color: #6086fe !important;
	height: -moz-fit-content;
	height: fit-content;
	color: white !important;
}

.home_page_content h2 {
	font-size: 32px;
	color: #000d72;
	text-shadow: 2px 2px 4px #7a87ff;
	margin-bottom: 20px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 600px;
}

.home_page_content p {
	font-size: 16px;
	color: #000d72;
	font-weight: 400;
	letter-spacing: 1px;
	text-shadow: 2px 2px 4px #b19dff;
	line-height: 22px;
	text-align: left;
	margin-bottom: 35px !important;
	/* margin-left: -10px !important; */
}

.features_page {
	display: flex;
	align-items: flex-start !important;
	flex-direction: column;
	text-align: left;
}

.People_heading {
	margin-bottom: 1rem;
}

.App_this {
	font-family: sans-serif;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 1.2;
	text-align: left;
	/* margin: 100px 0 40px; */
	color: #1dff00;
}








/************************/
/*     Testimonials     */
/************************/
.cards-2 {
	padding-bottom: 2.25rem;
	text-align: center;
}

.cards-2 .h2-heading {
	margin-bottom: 1.75rem;
}

.cards-2 .card {
	width: 100%;
	position: relative;
	margin-bottom: 5rem;
	border-radius: 10px;
	box-shadow: 0 4px 20px -1px rgba(0, 0, 0, 0.1);
}

.cards-2 .card .quotes {
	position: absolute;
	top: -15px;
	right: 0;
	left: 0;
	width: 48px;
	margin-right: auto;
	margin-left: auto;
}

.cards-2 .card-body {
	padding: 3.5rem 2rem 3.125rem 2rem;
}

.cards-2 .testimonial-author {
	margin-bottom: 0.125rem;
	color: #223150;
}

.cards-2 .occupation {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.cards-2 .card .gradient-floor {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 10px;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.cards-2 .card .gradient-floor.red-to-blue {
	background: linear-gradient(to right, #ef3d91, #0092ff);
}

.cards-2 .card .gradient-floor.blue-to-purple {
	background: linear-gradient(to right, #0092ff, #637eff);
}

.cards-2 .card .gradient-floor.purple-to-green {
	background: linear-gradient(to right, #637eff, #16c66d);
}


/********************/
/*    Customers     */
/********************/
.slider-1 {
	padding-top: 2rem;
	padding-bottom: 3.75rem;
	background: linear-gradient(rgb(252, 250, 251), rgb(255, 255, 255));
	text-align: center;
}

.slider-1 h4 {
	margin-bottom: 0.25rem;
}

.slider-1 .section-divider {
	margin-bottom: 1rem;
}

.slider-1 .slider-container {
	margin-bottom: 0.5rem;
	padding: 1rem 0;
}


/**********************/
/*     Invitation     */
/**********************/
.basic-3 {
	/* padding-bottom: 4rem; */
	background-size: cover;
	text-align: center;
}

.basic-3 .text-container {
	padding: 3rem 1.5rem 3.5rem 1.5rem;
	border-radius: 28px;
	/* background-color: #0d6eff; */
	background-image: url(./../../public/img/welcomepageichest.svg);
	background-size: cover;
	background-position: bottom;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	transition: all 500ms;
}
.basic-3 .text-container:hover{
	background-image: url(./../../public/img/welcomepagehover.svg);
	background-size: cover;
	background-position: top;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.basic-3 h2 {
	margin-bottom: 1rem;
	color: #000000;
}

.basic-3 .p-large {
	margin-bottom: 2.75rem;
	color: #000000;
}

.basic-3 .btn-solid-lg {
	border-color: #16c66d;
	background-color: #16c66d;
}

.basic-3 .btn-solid-lg:hover {
	border-color: #ffffff;
	background-color: transparent;
	color: #ffffff;
}


/*******************/
/*     Contact     */
/*******************/
.form-1 {
	padding-top: 9.5rem;
	padding-bottom: 8.75rem;
}

.form-1 .text-container {
	margin-bottom: 4rem;
}

.form-1 .section-divider {
	margin-bottom: 1rem;
}

.form-1 h2 {
	margin-bottom: 1.75rem;
}

.form-1 .list-unstyled .fas {
	font-size: 0.375rem;
	line-height: 1.625rem;
}

.form-1 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}


/******************/
/*     Footer     */
/******************/
.footer {
	position: relative;
	padding-top: 7.5rem;
	padding-bottom: 6rem;
	text-align: center;
}

.footer .decoration-circles {
	position: absolute;
	top: 96px;
	left: -60px;
}

.footer div[class*="col"] {
	/* selects all elements which have classes starting with col */
	/* needed so that the absolute positioned decoration stays behind */
	position: relative;
}

.footer a {
	color: #223150;
	text-decoration: none;
}

.footer h4 {
	margin-bottom: 1.875rem;
}

.footer .fa-stack {
	width: 2em;
	margin-bottom: 1.25rem;
	margin-right: 0.375rem;
	font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
	color: #0092ff;
	transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
	color: #ffffff;
	transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
	color: #ffffff;
}

.footer .fa-stack:hover .fa-stack-2x {
	color: #0092ff;
}



/******************************/
/*     Back To Top Button     */
/******************************/
#myBtn {
	position: fixed;
	z-index: 99;
	bottom: 20px;
	right: 20px;
	display: none;
	width: 52px;
	height: 52px;
	border: none;
	border-radius: 50%;
	outline: none;
	background-color: #44434a;
	cursor: pointer;
}

#myBtn:hover {
	background-color: #1d1d21;
}

#myBtn img {
	margin-bottom: 0.25rem;
	width: 18px;
}


/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
	padding-top: 9rem;
	padding-bottom: 5rem;
	background-color: #fcfafb;
}

.ex-basic-1 .list-unstyled .fas {
	font-size: 0.375rem;
	line-height: 1.625rem;
}

.ex-basic-1 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}

.ex-basic-1 .text-box {
	padding: 1.25rem 1.25rem 0.5rem 1.25rem;
	background-color: #fcfafb;
}

.ex-cards-1 .card {
	border: none;
	background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
	width: 2em;
	font-size: 1.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
	color: #0092ff;
}

.ex-cards-1 .card .fa-stack-1x {
	width: 2em;
	color: #ffffff;
	font-weight: 700;
	line-height: 2.125rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 {
	margin-left: 2.25rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 h5 {
	margin-top: 0.125rem;
	margin-bottom: 0.5rem;
}

/* Min-width 768px */
@media (min-width: 768px) {
	.tab_movile_view {
		display: none;
	}

	/* Header */
	.header {
		padding-top: 5rem;
		padding-bottom: 5em;
	}

	.header .h1-large {
		font-size: 2.25rem;
		line-height: 3.75rem;
	}

	/* end of header */


	/* Statistics */
	.counter .counter-cell {
		display: inline-block;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		vertical-align: top;
	}

	/* end of statistics */


	/* Extra Pages */
	.ex-basic-1 .text-box {
		padding: 1.75rem 2rem 0.875rem 2rem;
	}

	/* end of extra pages */
}

/* end of min-width 768px */


/* Min-width 992px */
@media (min-width: 992px) {

	/* General Styles */
	.h2-heading {
		width: 35.25rem;
		margin-right: auto;
		margin-left: auto;
	}

	.p-heading {
		width: 46rem;
		margin-right: auto !important;
		margin-left: auto !important;
	}

	/* end of general styles */


	/* Navigation */
	.navbar {
		padding-top: 1.75rem;
		/* background-color: #fcfafb; */
		box-shadow: none;
		transition: all 0.2s;
		z-index: 99999 !important;
	}

	.navbar.top-nav-collapse {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		/* background-color: #fcfafb; */
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	}

	.offcanvas-collapse {
		position: static;
		top: auto;
		bottom: auto;
		left: auto;
		width: auto;
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		overflow-y: visible;
		visibility: visible;
	}

	.offcanvas-collapse.open {
		-webkit-transform: none;
		transform: none;
	}

	.navbar .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
	}

	.navbar .nav-item .nav-link {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.navbar .dropdown-menu {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
	}

	.navbar .dropdown-divider {
		width: 90%;
	}

	.navbar .nav-item .btn-solid-sm {
		margin-top: 0;
		margin-left: 1rem;
	}


	/* Header */
	.header {
		overflow: hidden;
		display: flex;
		padding-top: 5rem;
		padding-bottom: 5rem;
		align-items: center;
		height: 100vh;
		justify-content: center;
	}

	.header .image-container {
		margin-top: 0;
	}

	.header .btn-solid-lg,
	.header .btn-outline-lg {
		margin-right: 0;
		margin-left: 0;
	}

	.header .btn-solid-lg {
		margin: 0px 25px;
	}

	/* end of header */


	/* Services */




	/* end of services */


	/* Details 1 */
	.basic-1 .image-container {
		margin-bottom: 0;
	}

	/* 
	.basic-1 .text-container {
		margin-top: 2rem;
	} */

	/* end of details 1 */


	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 3rem;
		margin-bottom: 0;
	}

	/* end of details 2 */


	/* Testimonials */
	.cards-2 .card {
		display: inline-block;
		width: 45%;
		vertical-align: top;
	}

	/* .cards-2 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	} */

	/* end of testimonials */


	/* Invitation */
	.basic-3 .text-container {
		padding-top: 3rem;
		width: 100%;
		padding-bottom: 3rem;
	}

	.basic-3 h2 {
		width: 42rem;
		margin-right: auto;
		margin-left: auto;
	}

	.basic-3 .p-large {
		width: 85%;
		text-align: justify !important;
		margin-right: auto !important;
		margin-left: auto !important;
	}

	/* end of invitation */


	/* Footer */
	.footer h4 {
		width: 46rem;
		margin-right: auto;
		margin-left: auto;
	}


	/* Extra Pages */
	.ex-cards-1 .card {
		display: inline-block;
		width: 296px;
		vertical-align: top;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
}

/* Min-width 1200px */
@media (min-width: 1200px) {

	/* General Styles */
	.container {
		max-width: 1140px;
	}

	/* Header */
	.header {
		overflow: hidden;
		display: flex;
		padding-top: 5rem;
		padding-bottom: 5rem;
		align-items: center;
		height: 100vh;
		justify-content: center;
	}

	.header .text-container {
		margin-top: 0;
	}

	.header .image-container {
		position: relative;
	}




	/* Details 1 */
	/* .basic-1 .text-container {
		margin-top: 5rem;
		margin-left: 2.75rem;
	} */

	/* end of details 1 */


	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 6rem;
		margin-right: 3rem;
	}

	.basic-2 .image-container {
		text-align: right;
	}

	/* end of details 2 */


	/* Features */
	.accordion-1 .accordion {
		margin-bottom: 0;
	}

	.accordion-1 .image-container {
		text-align: right;
	}

	/* end of features */


	/* Testimonials */
	.cards-2 .card {
		width: 345px;
	}

	.cards-2 .card:nth-of-type(3n+2) {
		margin-right: 1.125rem;
		margin-left: 1.125rem;
	}

	/* end of testimonials */


	/* Invitation */
	.basic-3 .text-container {
		padding-top: 3rem;
		width: 100%;
		padding-bottom: 3.5rem;
	}

	/* end of invitation */


	/* Contact */
	.form-1 .text-container {
		margin-top: 1.5rem;
		margin-right: 2.75rem;
	}

	.form-1 form {
		margin-left: 2.75rem;
	}

	/* end of contact */


	/* Footer */
	.footer .decoration-circles {
		left: 0;
	}

	/* end of footer */


	/* Extra Pages */
	.ex-cards-1 .card {
		width: 336px;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 2.875rem;
		margin-left: 2.875rem;
	}

	/* end of extra pages */
}

/* end of min-width 1200px */


/* navbar design Start */
.logo-image {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}

.logo-image:hover {
	text-decoration: none !important;
}

.navbar_slogan {
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.5px;
	color: #062568;
	height: 40px;
	line-height: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.navbar_slogan:hover {
	text-decoration: none !important;
}

.navbar_sub_slogan {
	font-weight: 400;
	font-size: 12px;
}

/* navbar design End */












/* From Uiverse.io by alexruix */
.profile_image_card {
	width: 100%;
	height: 200px;
}

.patner_profile_image {
	height: 200px;
	width: 100%;
	object-fit: cover;
	object-position: top;
}

.patners_card {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 10px;
	justify-content: space-evenly;
}

.Profile_card {
	width: 20%;
	min-width: 200px;
	height: 355px;
	border-radius: 20px;
	background: #a8b0f11f;
	position: relative;
	padding: 1.5rem;
	border: 2px solid #97b1f633;
	transition: 0.5s ease-out;
	overflow: visible;
}

.Profile_card-details {
	color: black;
	margin-top: 20px;
	gap: .5em;
	display: grid;
	text-align: left;
}

.Profile_card-button {
	transform: translate(-50%, 125%);
	width: 60%;
	border-radius: 1rem;
	border: none;
	background-color: #9fb5ff;
	color: #fff;
	font-size: 1rem;
	padding: .5rem 1rem;
	position: absolute;
	left: 50%;
	bottom: 0;
	opacity: 0;
	transition: 0.3s ease-out;
}

.text-body {
	font-size: 16px !important;
	font-family: var(--font-family-base) !important;
	color: rgb(0, 0, 0);
}

/*Text*/
.text-title {
	font-size: 1.5em;
	font-size: 16px !important;
	font-family: var(--font-family-heding) !important;
	font-weight: bold;
}

/*Hover*/
.Profile_card:hover {
	border-color: #7da2ff;
	box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.Profile_card:hover .Profile_card-button {
	transform: translate(-50%, 50%);
	opacity: 1;
}

.header {
	background-image: url(./../../public/img/background/homepagebcakground.svg);
	background-size: cover;
	background-position: center;
	margin-bottom: 70px !important;
}









/* Modal styles */
.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}

.card_modal_content {
	background-color: #fefefe;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 8px;
	width: 95%;
	max-width: 750px;
}

.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.modal-details {
	display: flex;
	flex-direction: row;

}

.card_design {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;
}

.card_image {
	height: 80px;
	width: 80px;
	border-radius: 50%;
	margin-right: 20px;
}

.card_profile_image {
	height: 100%;
	width: 100%;
	border-radius: 50%;
	object-fit: cover;

}

.details_card {
	text-align: justify;
	text-indent: 50px;
	height: 250px;
	padding-right: 10px;
	overflow-y: auto;
}

.second_paragraph {
	margin-top: 10px !important;
}

.card_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card_content h2,
p {
	line-height: 20px;
}

/* .container {
	padding-right: 0 !important;
} */

/* start  contact us  */
.contact_us {
	margin-top: 20px;
}

.button {
	position: relative;
	overflow: hidden;
	height: 3rem;
	z-index: 99;
	padding: 10px 20px;
	border-radius: 10px;
	/* background: #0092ff; */
	border: solid 2px #9a95ff  !important;
	background-color: inherit;
	background-size: 400%;
	color: #614bff;
	border: none;
	cursor: pointer;
	text-decoration: none;
}

.button:hover {
	color: white !important;
	text-decoration: none;
}

.button:hover::before {
	transform: scaleX(1);
}

.button-content {
	position: relative;
	z-index: 1;
	text-decoration: none;
}

.button::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	transform: scaleX(0);
	transform-origin: 0 50%;
	width: 100%;
	height: inherit;
	border-radius: 10px;
	background: linear-gradient(82.3deg,
			#0058db 10.8%,
			#0059ff 94.3%);
	transition: all 0.475s;
}

/* end contact us  */

.service_page {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	align-items: center;
}

.service_details {
	width: 50%;
	padding: 20px;
}

.service_details p {
	line-height: 30px;
		font-size: 16px;
		font-family: var(--font-family-base) !important;
		color: black !important;
}

.service_image {
	border-radius: 0px 0px 80px 0px;
	width: 30%;
	min-width: 280px;
	overflow: hidden;

}

.service_image_srvice {
	width: 100%;
	min-width: 280px;
	transition: all 0.4s ease-in-out;
}

.service_image_srvice:hover {
	transform: scale(1.1);
}


/* From Uiverse.io by neerajbaniwal */
.btn-shine {
	padding: 12px 48px;
	color: #fff;
	background: linear-gradient(to right, #15ff00 0, #fff 20%, #614bff 30%);
	background-position: 0;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: shine 6s infinite linear;
	animation-fill-mode: forwards;
	-webkit-text-size-adjust: none;
	font-weight: 600;
	font-size: 18px;
	text-decoration: none;
	border-radius: 10px;
	border:solid 2px #9a95ff !important;
	white-space: nowrap;
	font-family: "Poppins", sans-serif;
}

@-moz-keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

@-webkit-keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

@-o-keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

@keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

/* contact us page  */
.contact_us_header {
	margin-top: 62px;
}

.contact_us_details,
.text_center {
	text-align: center;
}

.liststyledesign {
	list-style: none !important;
	padding-left: 0 !important
}

.serviceList {
	background-color: #ecf5ff;
	height: fit-content;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
	transition: all 400ms;
	color: #000000;
	font-size:16px;
	font-family: var(--font-family-base) !important;
}

.serviceList:hover {
	background-color: #8793ff83;
	color: rgb(25, 21, 74);
}

.explorebutton {
	font-weight: 600;
	font-size: 14px;
	color: black;
	text-decoration: none;
	transition: all 500ms;
	width: 200px;
}

.explorebutton:hover {
	/* background-color: #003a7c; */
	padding: 0px 10px;
	border-radius: 5px;
	color: #a1a1ff;
	text-decoration: none;
}

#services,
#features,
#whyichest,
#aboutus {
	padding-top: 60px;
}


.key_Assets_main {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 40px 0px;
}

.Key_assets_base {
	width: 90vw;
	max-width: 1100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: row;
	gap: 10px;
	/* margin-top: 30px;  */
}

.key_assets_left,
.key_assets_right {
	width: 48%;
	min-width: 250px;
	max-width: 576px;
}

.Assets_card {
	margin-top: 20px;
	width: 100%;
	background-color: #ecf5ff;
	padding: 20px;
	border-radius: 10px;
	transition: all 300ms;
}

.Assetscard_Heading {
	transition: all 300ms;
	font-size: 18px;
	font-weight: 700;
	font-size: 18px;
	font-family: var(--font-family-heding) !important;
}

.Assets_card:hover,
.Assetscard_Heading:hover {
	background-color: #dcf0ff;
	transform: scale(1.02);
	color: #0059ff;
}

.Assetscard_paragraph {
	font-size: 16px;
	color: #000000;
	font-family: var(--font-family-base) !important;
}
.accordion-button{
	font-size: 18px !important;
	font-weight: 600 !important;
	color: #000000 !important;
	font-family: var(--font-family-base) !important;
}

.icest_main_card {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	list-style: none !important;
}

.icest_card {
	width: 45%;
	min-width: 250px;
	background-color: #ecf5ff;
	text-align: justify;
	padding: 20px 10px;
	border-radius: 10px;
	font-size: 15px;
	color: black;
	transition: all 300ms;
}
.icest_card:hover{
	background-color: #dcf0ff;
	transform: scale(1.02)
}
/* 
.secondndbckground{
background-image: url(./../../public/img/2nsbackground.svg);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
} */
