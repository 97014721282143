.homepage{
    margin-top: 20px;
    margin-left: 10px;
    padding: 0;
    /*background-color: yellow;*/
    height: 100vh;
}
.profile {
   /* background-color: green;*/
    height: 500px;
    width: 350px;
    background-color: #ebebeb;
    box-shadow:1px 1px 10px #00000057;
    transition: all ease 200ms;
}

.profile:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
.profilepic{
    margin-top: 15px;
    align-self: center;
    height: 20%;
    width: 29%;
    border-radius: 50px;
    border: solid 2px rgb(88, 88, 88);
   
}
.name{
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    align-self: center;
    margin-bottom: 40px;
}
.profilealign{
    color: rgb(0, 0, 0);
    margin-top: 15px;
    padding-left: 30px;
    align-self: self-start;
    font-weight: 400;
    font-size: 20px;
}
.button11{
    display:flex;
    
}
.trustee-header{
    position: sticky;
    background-color: #dcd2d2;
}
.tabe_rowsl{
    min-width:49px;
    width: 49px;
    max-width: 50px !important;
}
.tabe_row{
    min-width:150px;
    width: 200px;
    max-width: 200px !important;
    margin: 30px;
    text-align: center;
}