body,
:root {
    --box-shadow: rgba(102, 118, 147, 0.25) 0px 1px 1px,
        rgba(62, 74, 95, 0.13) 0px 0px 1px 1px;
    --font-family: "Roboto Condensed", sans-serif;
    --font-family-base: "Roboto Flex", sans-serif;
}

.lagacychart_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 10px;
    background-color: white;
}

.legacychart_base {
    width: 100%;
    height: 70px;
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    transition: height 0.3s ease;
}

.legacychart_base.expanded {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.lagacychart_details_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.lagacy_card_name {
    font-weight: 600;
    font-size: 15px;
    font-family: var(--font-family);
}

.lagacy_details {
    font-weight: 500;
    font-size: 13px;
    font-family: var(--font-family-base);
}

.legacy_button {
    margin: 10px 20px 10px 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid lightgray;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.lagacy_Icon {
    font-size: 10px;
}

.lagacy_details.incomplete {
    font-weight: 600;
    color: red;
    padding: 1px 5px;
    border-radius: 5px;
    background-color: rgb(255, 207, 207);
    width: 80px;
}

.lagacy_details.completed {
    font-weight: 600;
    color: rgb(0, 93, 0);
    background-color: rgb(181, 255, 181);
    padding: 1px 5px;
    border-radius: 5px;
    width: 80px;
}

.lagacy_details_cards {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #d0d6ff #f0f8ff;
}

.lagacy_details_cards::-webkit-scrollbar {
    height: 10px;
    border-radius: 50px;
}

.lagacy_detail_card {
    display: flex;
    min-width: fit-content;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px !important;
}

.legacy_row {
    font-size: 13px;
    font-family: var(--font-family-base) !important;
    display: flex;
    min-width: 110px;
    flex-direction: column;
    width: 170px;
}

.legacy_row strong {
    color: black;
}

.legacy_page_notification.incomplete {
    padding: 1px 5px;
    color: red;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family-base);
    width: fit-content;
    border-radius: 5px;
    background-color: rgb(255, 207, 207);
}

.legacy_page_notification.completed {
    color: rgb(0, 93, 0);
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family-base);
    background-color: rgb(181, 255, 181);
    padding: 1px 5px;
    border-radius: 5px;
}
.aegacy_row_action_btn{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 210px;
}
.legacy_row_Action{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family-base);
    border: 0.5px solid lightgray;
    padding: 2px 10px ;
    border-radius: 8px;
    text-decoration: none;
    gap: 10px;
    transition: all 400ms;
}
.legacy_row_Action:hover{
    gap: 15px;
    background-color: rgb(211, 217, 255);
    font-size: 14px !important;
    font-size: var(--font-family-base);
    text-decoration: none;
}
.legacy_popup_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
    z-index: 1000; /* Ensure it appears above other content */
}

.legacy_popup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    width: 100%;
}

.hidden {
    display: none;
}

.visible {
    height: 100%;
    width: 100%;
    display: flex;
}
