.restepassword_maincard {
    min-width: 270px;
    max-width: 450px;
    width: 90%;
    background-color: rgb(236, 236, 236);
    padding: 20px;
    border-radius: 10px;

}
@media (min-width:492px) {
    .restepassword_container {
        width: 100vw;
        min-height: 100vh;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .restepassword_card {
        height: auto;
    }

    .resetpassword_cardbody {
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #f3f8fa;
    }

    .card-header {
        height: 60px;
        border: none;
        background-color: #dcd2d2;
    }

    .restepass_logincard {
        margin-top: 40%;
        background-Color: #ebebeb;
        width: 100%;
        height: 500px;
        border-radius: 5px;
        transition: transform 0.3s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.145);

    }

    .restepass_logincard:hover {
        transform: scale(1.01);
        box-shadow: 4px 4px 10px gray;
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .restepass_logincard h3 {
        text-align: center;
        font-weight: bolder;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
        padding: 10px 0px 10px;
    }

    .login-card-details {
        color: rgb(0, 0, 0);
        padding: 10px;
    }

    .resetpassword_inputfiled {
        background-color: #f3f8fa !important;
    }

    .resetpassword_inputfiled::placeholder {
        color: rgb(0, 0, 0);
    }

    .resetpassword_heading {
        margin-top: 20px;
    }

    .loginbuttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 50px;
        margin-top: 10px;
    }

    .cler-button {
        width: 35%;
        border: 2px solid #4aafff;
        color: rgb(0, 0, 0);
        font-weight: 600;
        border-radius: 20px;

    }

    .cler-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .register-button {
        color: #181818;
        border: 2px solid #4aafff;
        font-weight: 600;
        width: 35%;
        border-radius: 20px;
    }

    .register-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .text-center {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-radius: 5px 5px 0px 0px;
        color: rgb(255, 0, 0);
    }

    .text-center-success {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-radius: 5px 5px 0px 0px;
        color: rgb(0, 131, 31);
        text-align: center;
    }

}

@media only screen and (max-width: 492px) {
    .restepassword_container {
        width: 100vw;
        min-height: 100vh;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .restepassword_card {
        height: auto;
    }

    .resetpassword_cardbody {
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #f3f8fa;
    }

    .card-header {
        height: 60px;
        border: none;
        background-color: #dcd2d2;
    }

    .restepass_logincard {
        margin-top: 20%;
        background-Color: #ebebeb;
        width: 100%;
        height: 500px;
        border-radius: 5px;
        transition: transform 0.3s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.145);

    }

    .restepass_logincard:hover {
        transform: scale(1.01);
        box-shadow: 4px 4px 10px gray;
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .restepass_logincard h3 {
        text-align: center;
        font-weight: bolder;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
        padding: 10px 0px 10px;
    }

    .login-card-details {
        color: rgb(0, 0, 0);
        padding: 10px;
    }

    .resetpassword_inputfiled {
        background-color: #f3f8fa !important;
    }

    .resetpassword_inputfiled::placeholder {
        color: rgb(0, 0, 0);
    }

    .resetpassword_heading {
        margin-top: 5px;
    }

    .loginbuttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
    }

    .cler-button {
        width: 35%;
        border: 2px solid #4aafff;
        color: rgb(0, 0, 0);
        font-weight: 600;
        border-radius: 20px;

    }

    .cler-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .register-button {
        color: #181818;
        border: 2px solid #4aafff;
        font-weight: 600;
        width: 35%;
        border-radius: 20px;
    }

    .register-button:hover {
        color: #000;
        border: 2px solid #ff5a58;
        background-color: inherit;
    }

    .text-center {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-radius: 5px 5px 0px 0px;
        color: rgb(255, 0, 0);
    }

    .text-center-success {
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-radius: 5px 5px 0px 0px;
        color: rgb(0, 131, 31);
        text-align: center;
    }
}