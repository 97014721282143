.add_trustee_base {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.add_trustee_main {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 5px;
  height: 84vh;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.add_trustee_main::-webkit-scrollbar {
  width: 2px;
}
.add_trustee_main::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
}

.add_trustee_main::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
.add_trustee_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edefff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    border-radius: 5px;
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  border-radius: 5px;
}
.add_trustee_details {
  display: flex;
}
.add_trustee_details img {
  height: 60px;
  width: 60px;
  margin: 5px 20px;
  border-radius: 50%;
  border: solid 1px gray;
}
.add_trustee_name_email {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
}
.add_trustee_name {
  margin-bottom: 0px;
  display: flex;
  list-style: none;
  flex-direction: row;
}
.add_trustee_name_line {
  width: 70px;
  font-weight: 600;
}
.add_trustee_email {
  display: flex;
  margin-bottom: 0px;
  list-style: none;
  flex-direction: row;
}
.add_trustee_email_line {
  width: 70px;
  font-weight: 600;
}
.add_trustee_btn {
  display: flex;
  justify-content: space-evenly;
  margin-right: 20px;
}
.add_trustee_details_btn {
  font-weight: 530;
  display: flex;
  justify-content: center;
  width: 150px;
  /* margin-bottom: 10px; */
  border: none;
  background-color: #0051ff23;
  padding: 5px 10px;
  border-radius: 3px;
  transition: all 300ms;
}

.add_trustee_details_btn:hover{
  background-color:rgb(77, 93, 236);
  color: white;
}
.add_trustee_Add:hover{
  text-decoration: none !important;
  color:black;
}
.add_trustee_Add {
  margin-left: 20px;
  color: black;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: center;
  width: 150px;
  text-decoration: none;
  background-color: #a2fab0;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 530;
}
.btn-sendRequest {
  border: 2px solid rgb(255, 191, 0);
  padding: 10px;
  border-radius: 20px;
  transition: 250ms;
}
.btn-sendRequest:hover {
  background-color: rgb(255, 191, 0);
  color: white;
}
.btn-sendRequest:focus {
  background-color: rgb(255, 191, 0);
  color: white;
}
.table2 {
  margin: 20px;
  /* border: 1px solid black; */
  box-shadow: 1px 1px -15px black;
}
/* .table2,.tablehead,.tablebody{
    border: 1px solid black;
} */
.tablehead > tr > th {
  /* border: 1px solid black; */
  padding: 10px;
}
.tablebody > tr {
  border-top: 1px solid black;
  border-width: 80%;
  border-bottom: 1px solid black;
  text-align: left;
  padding: 10px;
}

@media screen and (min-width: 470px) and (max-width: 850px) {
  .add_trustee_list {
    display: flex;
    align-items: center;
    height: 115px;
    background-color: #edefff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    border-radius: 5px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    border-radius: 5px;
    flex-direction: column;
  }
  .add_trustee_btn {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 470px) {
  .add_trustee_details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .add_trustee_btn{
    display: flex;
    align-items: center;

    flex-direction: column;
  }
  .add_trustee_details_btn{
    margin-bottom: 10px;
    margin: 0px !important;
    width: 200px !important;

  }
  .add_trustee_Add{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    width: 200px !important;
  }
  .add_trustee_list {
    display: flex;
    height: 220px;
    align-items: center;
    background-color:#edefff;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    border-radius: 5px;
    flex-direction: column;
  }
  .add_trustee_btn {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 5px;
  }
  .add_trustee_details_btn {
    width: 120px;
    border: none;
  }
  .add_trustee_Add {
    width: 120px;
    text-decoration: none;
  }
}
