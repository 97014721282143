.edit-button{
    /* width: 90px; */
   font-size: 16px;
   color: #4aafff;
   /* background-color: #ffffff; */
   text-align: center;
   /* padding: 5px 20px; */
   border-radius:5px;
   text-transform: capitalize;
}

@media  (max-width:640px) {
    .edit-button{
        font-size: 13px;
    }
 }
